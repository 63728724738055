import { post, get, postByQuery } from '@/api/request'

//添加菜单
export const menuAdd = params => {
    return post('/auth/menuAdd', params)
}

//菜单列表（分页）
export const menuQueryList = params => {
    return post('/auth/menuQueryList', params)
}

//菜单列表（不分页）
export const menuQueryPage = params => {
    return post('/auth/menuQueryPage', params)
}

//通过ID获取单条数据
export const menuSelectOne = params => {
    return get('/auth/menuSelectOne', params)
}

//菜单修改
export const menuEdit = params => {
    return post('/auth/menuEdit', params)
}

//菜单删除
export const menuDelete = params => {
    return postByQuery('/auth/menuDelete', params)
}
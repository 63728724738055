<template>
  <div class="page_wrap">
    <div class="card_wrap">
      <div class="card color1">
        <span>{{ orderCount }}</span>
        <span>监控订单数量</span>
        <i class="icon-single iconfont"></i>
      </div>
      <div class="card color2">
        <span>{{ equipmentCount }}</span>
        <span>监控设备数量</span>
        <i class="icon-shebei1 iconfont"></i>
      </div>
      <div class="card color3">
        <span>{{ equipErrorCount }}</span>
        <span>设备异常报警</span>
        <i class="icon-shebeibaojing iconfont"></i>
      </div>
      <div class="card color4">
        <span>{{ noticeOrderCount }}</span>
        <span>重点关注订单</span>
        <i class="icon-wendangdingdan iconfont"></i>
      </div>
      <div class="card color5">
        <span>{{ fenceWarnCount }}</span>
        <span>电子围栏报警</span>
        <i class="icon-iconhuizong_huaban1fuben11 iconfont"></i>
      </div>
    </div>

    <div class="chart_view">
      <div class="chartLine">
        <dailyChart ref="dailyChart"></dailyChart>
      </div>
      <div class="chartcircle">
        <circleChart ref="circleChart"></circleChart>
      </div>
    </div>

    <div class="list_view">
      <span>对接系统健康状态</span>
      <div class="list_view_cont">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column type="expand">
            <template slot-scope="props">
              <div v-if="props.row.Data && props.row.Data.length > 0">
                <div v-for="(item, index) in props.row.Data" :key="index" class="bottom_border">
                  {{ item }}
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column type="index" label="序号" fixed align="left" width="50" />
          <el-table-column prop="SystemName" label="已对接业务系统">
          </el-table-column>
          <el-table-column prop="DockingDate" label="查询日期">
          </el-table-column>
          <el-table-column label="系统对接状态">
            <template slot-scope="scope">
              <el-tag type="success" effect="dark" v-if="scope.row.DockingStatus == 1">正常</el-tag>
              <el-tag type="danger" effect="dark" v-else>故障</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="ErrorMsg" label="说明"> </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import dailyChart from "./components/dailyWarn";
import circleChart from "./components/circlePanel";
import dashboard from "@/api/dashboard";
export default {
  data() {
    return {
      orderCount: 0,
      equipmentCount: 0,
      equipErrorCount: 0,
      noticeOrderCount: 0,
      fenceWarnCount: 0,
      tableData: [],
    };
  },
  components: {
    dailyChart,
    circleChart,
  },
  mounted() {
    this.$nextTick(() => {
      this.loadData();
    });
  },
  methods: {
    loadData() {
      dashboard.getOrderCount().then((res) => {
        if (res.code == 0) {
          this.orderCount = res.result.orderNum;
          this.equipmentCount = res.result.entityNum;
          this.noticeOrderCount = res.result.focusNum;
        }
      });

      dashboard.getWarnCount4Hour().then((res) => {
        if (res.code == 0 && this.$refs.dailyChart) {
          this.$refs.dailyChart.drawLine(res.result);
        }
      });

      dashboard.getWarnCount().then((res) => {
        if (res.code == 0 && this.$refs.circleChart) {
          this.$refs.circleChart.drawCircle(res.result);
          let fenceCount = 0;
          for (let i = 0; i < res.result.length; i++) {
            if (res.result[i].name == "设备报警") {
              this.equipErrorCount = Number(res.result[i].value);
            }
            if (
              res.result[i].name == "入围栏报警" ||
              res.result[i].name == "出围栏报警"
            ) {
              fenceCount += Number(res.result[i].value);
            }
            this.fenceWarnCount = fenceCount;
          }
        }
      });
      dashboard.getConnectStatus().then((res) => {
        if (res.code == 0) {
          this.tableData = res.result;
        }
      });
    },
  },
};
</script>
<style scoped>
.page_wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.card_wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 10px;
  padding: 20px;
  width: 15%;
  position: relative;
}

.card span:nth-child(1) {
  color: #fff;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 10px;
}

.card span:nth-child(2) {
  color: #fff;
  font-size: 16px;
}

.color1 {
  background-image: -webkit-linear-gradient(45deg, #1cd0ca, #3ba3f3, #5283f9);
}

.color2 {
  background-image: -webkit-linear-gradient(45deg, #f48bee, #bf80f4, #777efa);
}

.color3 {
  background-image: -webkit-linear-gradient(45deg, #37f1a7, #25cec8, #07aee8);
}

.color4 {
  background-image: -webkit-linear-gradient(45deg, #ff958e, #ff7ca7, #f471b6);
}

.color5 {
  background-image: -webkit-linear-gradient(45deg, #3ca3f1, #17c0c6, #1ccfc6);
}

.card i {
  position: absolute;
  display: block;
  left: 60%;
  font-size: 4em;
  top: 10px;
  color: #bdbdbd;
}

.chart_view {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}

.chartLine {
  width: 70%;
  height: 400px;
  background: #fff;
}

.chartcircle {
  width: 30%;
  height: 400px;
  background: #fff;
}

.list_view {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  background-color: #fff;
}

.list_view span {
  margin-left: 20px;
  margin-top: 10px;
  color: #2c2c2c;
  font-weight: bold;
}

.list_view_cont {
  display: flex;
  margin: 20px;
  width: 96%;
}

.bottom_border {
  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 5px;
  margin-bottom: 10px;
}
</style>
import { post, get, postByQuery } from '@/api/request'

//添加门店
export const shopAdd = params => {
    return post('/city/shopAdd', params)
}

//门店列表（分页）
export const shopQueryPage = params => {
    return post('/city/shopQueryPage', params)
}

//门店列表（不分页）
export const shopQueryList = params => {
    return post('/city/shopQueryList', params)
}

//通过ID获取单条数据
export const shopSelectOne = params => {
    return get('/city/shopSelectOne', params)
}

//门店修改
export const shopEdit = params => {
    return post('/city/shopEdit', params)
}

//门店删除
export const shopDelete = params => {
    return postByQuery('/city/shopDelete', params)
}
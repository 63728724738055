<template>
  <div class="pos_wrap">
    <baidu-map class="map" :center="center" :zoom="zoom" @ready="handler">
      <!--      <bm-driving start="天安门" end="百度大厦" @searchcomplete="handleSearchComplete" :panel="false" :autoViewport="true"></bm-driving>-->
      <!--      <bml-lushu-->
      <!--          @stop="reset"-->
      <!--          :path="path"-->
      <!--          :icon="icon"-->
      <!--          :play="play"-->
      <!--          :rotation="true">-->
      <!--      </bml-lushu>-->
      <bm-control :offset="{ width: 20, height: 20 }">
        <div class="pos_control_panel">
          <el-select
            v-model="activeFence"
            placeholder="请选择设备"
            @change="selectEquip"
            style="margin-right: 10px"
          >
            <el-option
              v-for="item in equipList"
              :key="item.entityNumber"
              :label="
                formateEquipType(item.entityType) +
                ' | ' +
                item.entityNumber +
                ' | ' +
                formateEquipFactory(item.entityFactory)
              "
              :value="item.entityNumber + '|' + item.entityFactory"
            >
            </el-option>
          </el-select>
          <el-checkbox v-model="fenceVisible">显示围栏</el-checkbox>
        </div>
      </bm-control>
      <bm-marker
        :position="center"
        :icon="icon"
        @click="showInfoWindow = !showInfoWindow"
        :rotation="posInfo.direction"
      />
      <bm-info-window
        :position="center"
        title=""
        :show="showInfoWindow"
        @close="showInfoWindow = false"
        :offset="{ width: 0, height: -14 }"
        style="width: 240px"
      >
        <p class="w_desc">{{ posInfo.carNumber }}-{{ posInfo.carName }}</p>
        <p class="w_desc">
          {{ posInfo.locDesc }}
        </p>
        <div class="w_item">
          <span>坐标：</span>
          <span
            >经度：{{ posInfo.longitude }} , 纬度：{{ posInfo.latitude }}</span
          >
        </div>
        <div class="w_item">
          <span>速度：</span>
          <span>{{ posInfo.speed }}km/h</span>
        </div>
        <div class="w_item">
          <span>定位时间：</span>
          <span>{{ timeFormate(posInfo.locTime) }}</span>
        </div>
        <div class="w_item">
          <span>服务器时间：</span>
          <span>{{ posInfo.heartTime }}</span>
        </div>
        <div class="w_item">
          <span>设备状态：</span>
          <span>{{ posInfo.offline == 1 ? "离线" : "在线" }}</span>
        </div>
        <div class="w_item">
          <span>设备号：</span>
          <span>{{ posInfo.deviceId }}</span>
        </div>
        <div v-if="false" class="w_item">
          <span>设备名称：</span>
          <span>{{ posInfo.deviceName }}</span>
        </div>
        <div class="w_item">
          <span>设备类型：</span>
          <span>{{ formateEquipType(posInfo.deviceType) }}</span>
        </div>
        <div class="w_item">
          <span>设备厂家：</span>
          <span>{{ formateEquipFactory(posInfo.deviceFactory) }}</span>
        </div>
        <div class="w_item">
          <span>里程：</span>
          <span>{{ posInfo.mil }}</span>
        </div>
        <div class="w_item">
          <span>定位信号类型：</span>
          <span>{{ signalTypeFormat(posInfo.signalType) }}</span>
        </div>
        <div class="w_item">
          <el-button
            class="label"
            type="text"
            icon="el-icon-aim"
            @click="
              toTrace(
                posInfo.deviceId,
                timeFormate(posInfo.locTime),
                posInfo.deviceFactory
              )
            "
            >轨迹</el-button
          >
        </div>
      </bm-info-window>
      <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" />
      <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT" />
      <bm-polygon
        :path="path"
        v-for="(path, $index) in polygonLayerList"
        :key="$index"
        fillColor="rgb(12,21,46)"
        stroke-color="blue"
        :stroke-opacity="0.5"
        :stroke-weight="2"
      />

      <bm-label
        :content="pos.name"
        v-for="(pos, $index) in labelPos"
        :key="$index + '_' + pos"
        :position="{ lng: pos.lng, lat: pos.lat }"
        :labelStyle="{
          color: 'white',
          fontSize: '18px',
          background: 'black',
          border: '',
        }"
      />
    </baidu-map>
  </div>
</template>
<script>
import BaiduMap from "vue-baidu-map/components/map/Map.vue";
import { Loading, Message } from "element-ui";
import traceApi from "@/api/trace";
import { formatTimeToStr } from "@/api/date";
import monitorApi from "@/api/monitor";
import fenceApi from "@/api/fence";
import {
  formatFactory,
  equipTypeFormat,
  signalTypeFormat,
} from "@/api/carview";
export default {
  components: {
    BaiduMap,
    // BmlLushu
  },
  data() {
    return {
      showInfoWindow: false,
      path: [],
      icon: {
        url: require("@/assets/images/car.png"),
        size: { width: 52, height: 26 },
        opts: { anchor: { width: 27, height: 13 } },
      },
      center: {},
      zoom: 3,
      locationLoading: null,
      posInfo: Object,
      activeFence: "",
      equipList: [],
      fenceList: [],
      mapObj: null,
      fenceVisible: false,
      mapInstance: null,
      circleLayerList: [],
      polygonLayerList: [],
      labelPos: [],
    };
  },
  props: {
    orderNumber: String,
    city: String,
    page: String,
    equipNumber: String,
    equipFactory: String,
  },
  mounted() {
    this.locationLoading = Loading.service({
      text: "加载地理位置",
      background: "rgba(0, 0, 0, 0.8)",
    });
    console.log(this.$route.query.orderNumber);
    this.orderNumber = this.$route.query.orderNumber;
    this.equipNumber = this.$route.query.equipNumber;
    this.page = this.$route.query.page;
    this.equipFactory = this.$route.query.equipFactory;
  },
  watch: {
    fenceVisible(nv) {
      if (nv) {
        this.getFenceList(this.mapInstance._bmap, this.mapInstance._map);
      } else {
        for (let i = 0; i < this.circleLayerList.length; i++) {
          this.mapInstance._map.removeOverlay(this.circleLayerList[i]);
        }

        this.circleLayerList = [];
        this.polygonLayerList = [];
        this.labelPos = [];
      }
    },
  },
  methods: {
    getVehiclePos(number, factory) {
      traceApi.getCurrentPos(number, factory).then((res) => {
        if (res.code == 0) {
          this.center = { lng: res.result.longitude, lat: res.result.latitude };
          this.showInfoWindow = true;
          this.posInfo = res.result;
          console.log(res.result);
        } else {
          Message.error({
            message: "实时位置获取失败！",
          });
        }
        this.$nextTick(() => {
          this.locationLoading.close();
        });
      });
    },
    handler({ BMap, map }) {
      let self = this;
      // let mapStyle={style:'grayscale'}
      // map.setMapStyle(mapStyle);
      self.zoom = 15;
      map.enableScrollWheelZoom();
      self.mapInstance = { _bmap: BMap, _map: map };
      // if(BMap){
      //   const geolocation = new BMap.Geolocation()
      //   geolocation.getCurrentPosition(res => {
      //     const { lng, lat } = res.point
      //     // const { province, city, district, street, street_number } = res.address
      //     self.center = { lng, lat }
      //     this.$nextTick(() => {
      //       this.locationLoading.close()
      //     })
      //   })
      // }else{
      //   this.$nextTick(() => {
      //     this.locationLoading.close()
      //   })
      // }
      if (this.page) {
        this.activeFence =
          this.equipNumber + "|" + this.formateEquipFactory(this.equipFactory);
        this.getVehiclePos(this.equipNumber, this.equipFactory);
      } else {
        this.getEquipList();
      }
    },
    getEquipList() {
      monitorApi.getEquipListInOrder(this.orderNumber).then((res) => {
        if (res.code == 0) {
          this.equipList = res.result;
          if (this.equipList.length > 0) {
            let equipNumber = this.equipList[0].entityNumber;
            if (this.equipNumber) {
              equipNumber = this.equipNumber;
            }

            let factory = this.equipList[0].entityFactory;
            this.activeFence = equipNumber + "|" + factory;
            this.getVehiclePos(equipNumber, factory);
          } else {
            Message.error({
              message: "未找到设备",
            });
          }
        } else {
          Message.error({
            message: res.msg,
          });
        }
        this.$nextTick(() => {
          this.locationLoading.close();
        });
      });
    },
    getFenceList(BMap, map) {
      let param = {
        // city:this.city
      };
      this.labelPos = [];
      fenceApi.findByCity(param).then((res) => {
        if (res.code == 0) {
          this.fenceList = res.result;
          for (let i = 0; i < this.fenceList.length; i++) {
            if (this.fenceList[i].shape == 1) {
              let circleLayer = new BMap.Circle(
                {
                  lng: this.fenceList[i].longitude,
                  lat: this.fenceList[i].latitude,
                },
                this.fenceList[i].radius,
                { strokeColor: "blue", strokeWeight: 0.5, strokeOpacity: 0.85 }
              );
              map.addOverlay(circleLayer);
              this.circleLayerList.push(circleLayer);
              this.labelPos.push({
                lng: this.fenceList[i].longitude,
                lat: this.fenceList[i].latitude,
                name: this.fenceList[i].fenceName,
              });
            }
            if (this.fenceList[i].shape == 2) {
              let vertexArrary = this.fenceList[i].vertexes.split(";");
              let posArrary = [];
              let _x = 0,
                _y = 0;
              for (let j = 0; j < vertexArrary.length; j++) {
                let p = vertexArrary[j].split(",");
                let pos = { lng: p[1], lat: p[0] };
                posArrary.push(pos);
                _x = _x + parseFloat(p[1]);
                _y = _y + parseFloat(p[0]);
              }

              this.polygonLayerList.push(posArrary);

              _x = _x / vertexArrary.length;
              _y = _y / vertexArrary.length;

              this.labelPos.push({
                lng: _x,
                lat: _y,
                name: this.fenceList[i].fenceName,
              });
            }
          }
        } else {
          Message.error({
            message: "未找到设备",
          });
          this.$nextTick(() => {
            this.locationLoading.close();
          });
        }
      });
    },
    timeFormate(val) {
      return formatTimeToStr(val);
    },
    selectEquip(val) {
      let param = val.split("|");
      this.getVehiclePos(param[0].trim(), param[1].trim());
    },
    toTrace(macid, realPickTime, entity_factory) {
      let params = {
        equipId: macid,
        pickTime: realPickTime,
        factory: entity_factory,
      };

      const { href } = this.$router.resolve({
        path: "/trace",
        query: params,
      });
      window.open(href, "_blank", "toolbar=yes, width=900, height=700");
    },
    formateEquipFactory(v) {
      return formatFactory(v);
    },
    formateEquipType(v) {
      return equipTypeFormat(v);
    },
    signalTypeFormat(v) {
      return signalTypeFormat(v);
    },
  },
};
</script>
<style scoped>
.pos_wrap {
  width: 100%;
  height: 100%;
  background: #8c939d;
}
.map {
  width: 100%;
  height: 100%;
  margin: 0px;
}
.w_desc {
  margin-top: 10px;
  color: #2c2c2c;
  font-weight: bold;
}
.w_item span:nth-child(1) {
  color: #2c2c2c;
  font-weight: bold;
  margin-top: 4px;
}
.pos_control_panel {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  padding: 4px;
  padding-right: 10px;
  box-shadow: 4px 4px 4px #8c939d;
}
</style>

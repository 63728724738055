<template>
  <div class="user_info">
    <!--    <div class="user_name">-->
    <!--      <el-image :src="userInfo.userImage" fit="fix" style="width: 200px;height: 200px"/>-->
    <!--      <span>{{userInfo.userName}}</span>-->
    <!--    </div>-->
    <div class="user_detail">
      <div class="item">
        <div><b>四海行</b></div>
      </div>
      <el-divider />
      <div>
        <div>
          <el-checkbox-group
            v-model="selectionshxArr"
            @change="handleChangeSHX()"
          >
            <el-checkbox v-for="sb in alarmshx" :label="sb.id" :key="sb.name">{{
              sb.name
            }}</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <el-divider />
      <div class="item">
        <div><b>中瑞</b></div>
      </div>
      <el-divider />
      <div>
        <div>
          <el-checkbox-group
            v-model="selectionzrArr"
            @change="handleChangeZR()"
          >
            <el-checkbox v-for="sb in alarmzr" :label="sb.id" :key="sb.name">{{
              sb.name
            }}</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <el-divider />
      <div class="item">
        <div><b>赛格</b></div>
      </div>
      <el-divider />
      <div>
        <div>
          <el-checkbox-group
            v-model="selectionsgArr"
            @change="handleChangeSG()"
          >
            <el-checkbox v-for="sb in alarmsg" :label="sb.id" :key="sb.name">{{
              sb.name
            }}</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <el-divider />
      <div class="item">
        <div><b>艾能</b></div>
      </div>
      <el-divider />
      <div>
        <div>
          <el-checkbox-group
            v-model="selectionanArr"
            @change="handleChangeAN()"
          >
            <el-checkbox v-for="sb in alarman" :label="sb.id" :key="sb.name">{{
              sb.name
            }}</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <el-divider />
      <div class="item btns">
        <div>
          <el-button
            type="primary"
            icon="el-icon-plus"
            @click="submitForm()"
            class="ml20 mt10 searchBtn"
            >保存</el-button
          >
        </div>
        <div>
          <el-button
            type="primary"
            @click="closeDialog()"
            class="ml20 mt10 searchBtn"
            >取消</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { formatTimeToStr } from "@/api/date";
import { isCarStatus, isCarType } from "@/api/carview";
import alarmApi from "@/api/alarm";
import { Message } from "element-ui";
export default {
  data() {
    return {
      carInfo: {
        userImage: require("@/assets/images/carIcon.png"),
        userName: "TestUser",
        realName: "张三",
        contactPlace: "沈阳市皇姑区XX街XX路",
        contactPhone: "13900000000",
        joinTime: "2021-04-21 00:00:00",
      },
      alarmshx: [], //四海行数据绑定
      alarman: [], //艾能数据绑定
      alarmzr: [], //中瑞数据绑定
      alarmsg: [], //中瑞数据绑定
      selectionshxArr: [], // 四海行选中对象
      selectionanArr: [], // 艾能选中对象
      selectionzrArr: [], //中瑞选中对象
      selectionsgArr: [], //赛格选中对象
      checkEquip: [], //数据库返回所有返回对象
    };
  },
  props: {
    orderId: String,
    carInfoObj: Object,
    strordertype: String,
  },
  mounted() {
    this.$nextTick(() => {
      let param = {
        entityFactory: 1, //1-四海行，2-艾能，3-自有硬件,4中瑞,5赛格
      };
      //console.log(this.strordertype);
      alarmApi.getAlarmTypeList(param).then((res) => {
        if (res.code == 0) {
          this.alarmshx = res.result;
          for (let i = 0; i < this.alarmshx.length; i++) {
            if (this.strordertype == 1) {
              //运营
              if (
                this.alarmshx[i].entityFactory == 1 &&
                this.alarmshx[i].status == 0
              ) {
                this.selectionshxArr.push(this.alarmshx[i].id);
              }
            }
            if (this.strordertype == 2) {
              //非运营
              if (
                this.alarmshx[i].entityFactory == 1 &&
                this.alarmshx[i].noperType == 0
              ) {
                this.selectionshxArr.push(this.alarmshx[i].id);
              }
            }
            if (this.strordertype == 3) {
              //在库
              if (
                this.alarmshx[i].entityFactory == 1 &&
                this.alarmshx[i].incarType == 0
              ) {
                this.selectionshxArr.push(this.alarmshx[i].id);
              }
            }
          }
        }
      });
      param = {
        entityFactory: 2, //1-四海行，2-艾能，3-自有硬件，4中瑞，5赛格
      };
      alarmApi.getAlarmTypeList(param).then((res) => {
        if (res.code == 0) {
          this.alarman = res.result;
          for (let i = 0; i < this.alarman.length; i++) {
            if (this.strordertype == 1) {
              //运营
              if (
                this.alarman[i].entityFactory == 2 &&
                this.alarman[i].status == 0
              ) {
                this.selectionanArr.push(this.alarman[i].id);
              }
            }
            if (this.strordertype == 2) {
              //非运营
              if (
                this.alarman[i].entityFactory == 2 &&
                this.alarman[i].noperType == 0
              ) {
                this.selectionanArr.push(this.alarman[i].id);
              }
            }
            if (this.strordertype == 3) {
              //在库
              if (
                this.alarman[i].entityFactory == 2 &&
                this.alarman[i].incarType == 0
              ) {
                this.selectionanArr.push(this.alarman[i].id);
              }
            }
          }
        }
      });
      param = {
        entityFactory: 4, //1-四海行，2-艾能，3-自有硬件，4中瑞，5赛格
      };
      alarmApi.getAlarmTypeList(param).then((res) => {
        if (res.code == 0) {
          this.alarmzr = res.result;
          for (let i = 0; i < this.alarmzr.length; i++) {
            if (this.strordertype == 1) {
              //运营
              if (
                this.alarmzr[i].entityFactory == 4 &&
                this.alarmzr[i].status == 0
              ) {
                this.selectionzrArr.push(this.alarmzr[i].id);
              }
            }
            if (this.strordertype == 2) {
              //非运营
              if (
                this.alarmzr[i].entityFactory == 4 &&
                this.alarmzr[i].noperType == 0
              ) {
                this.selectionzrArr.push(this.alarmzr[i].id);
              }
            }
            if (this.strordertype == 3) {
              //在库
              if (
                this.alarmzr[i].entityFactory == 4 &&
                this.alarmzr[i].incarType == 0
              ) {
                this.selectionzrArr.push(this.alarmzr[i].id);
              }
            }
          }
        }
      });
      param = {
        entityFactory: 5, //1-四海行，2-艾能，3-自有硬件，4中瑞，5赛格
      };
      alarmApi.getAlarmTypeList(param).then((res) => {
        console.log(res.result);
        if (res.code == 0) {
          this.alarmsg = res.result;
          for (let i = 0; i < this.alarmsg.length; i++) {
            if (this.strordertype == 1) {
              //运营
              if (
                this.alarmsg[i].entityFactory == 5 &&
                this.alarmsg[i].status == 0
              ) {
                this.selectionsgArr.push(this.alarmsg[i].id);
              }
            }
            if (this.strordertype == 2) {
              //非运营
              if (
                this.alarmsg[i].entityFactory == 5 &&
                this.alarmsg[i].noperType == 0
              ) {
                this.selectionsgArr.push(this.alarmsg[i].id);
              }
            }
            if (this.strordertype == 3) {
              //在库
              if (
                this.alarmsg[i].entityFactory == 5 &&
                this.alarmsg[i].incarType == 0
              ) {
                this.selectionsgArr.push(this.alarmsg[i].id);
              }
            }
          }
        }
      });
      // param = {
      //   status:0,
      // }
      // alarmApi.getAlarmTypeList(param).then(res=>{
      //   if(res.code == 0){
      //     let arr = res.result
      //     //this.checkEquip = res.result
      //      console.log(arr);

      //   }
      // })
      // // 初始化默认选中状态
      // //let checkArr = []
    });
  },
  methods: {
    handleChangeSHX() {
      // this.checkEquipArr = []
      // let arr = this.selectionshxArr
      // for (let i = 0; i < arr.length; i ++) {
      //   let equipment = arr[i]
      //   if (equipment.length > 0) {
      //     let obj = {
      //       id: this.equipments[i].id,
      //       equips: []
      //     }
      //     for (let j = 0; j < equipment.length; j++) {
      //       obj.equips.push(equipment[j])
      //     }
      //     this.checkEquipArr.push(obj)
      //   }
      // }
      // console.log(this.checkEquipArr);\
      // console.log(this.selectionanArr);
      // console.log(this.selectionshxArr);
    },
    handleChangeAN() {
      // this.checkEquipArr = []
      // let arr = this.selectionanArr
      // for (let i = 0; i < arr.length; i ++) {
      //   let equipment = arr[i]
      //   if (equipment.length > 0) {
      //     let obj = {
      //       id: this.equipments[i].id,
      //       equips: []
      //     }
      //     for (let j = 0; j < equipment.length; j++) {
      //       obj.equips.push(equipment[j])
      //     }
      //     this.checkEquipArr.push(obj)
      //   }
      // }
      // console.log(this.checkEquipArr);
      //  console.log(this.selectionanArr);
      //  console.log(this.selectionshxArr);
    },
    handleChangeZR() {},
    handleChangeSG() {},
    submitForm() {
      let savelist = ""; //四海行
      let edittype = "status"; //修改类型
      if (this.strordertype == 1) {
        //非运营
        edittype = "status";
      }
      if (this.strordertype == 2) {
        //运营
        edittype = "noperType";
      }
      if (this.strordertype == 3) {
        //在库
        edittype = "incarType";
      }
      for (let i = 0; i < this.selectionshxArr.length; i++) {
        // let obj = {
        //   id: this.selectionshxArr[i],
        //   status:"0"
        // }
        savelist +=
          '{"id":' + this.selectionshxArr[i] + ',"' + edittype + '":"0"}';
      }
      //艾能
      for (let i = 0; i < this.selectionanArr.length; i++) {
        // let obj = {
        //   id: this.selectionanArr[i],
        //   status:"0"
        // }
        savelist +=
          '{"id":' + this.selectionanArr[i] + ',"' + edittype + '":"0"}';
      }
      //中瑞
      for (let i = 0; i < this.selectionzrArr.length; i++) {
        // let obj = {
        //   id: this.selectionanArr[i],
        //   status:"0"
        // }
        savelist +=
          '{"id":' + this.selectionzrArr[i] + ',"' + edittype + '":"0"}';
      }
      //赛格
      console.log(this.selectionsgArr);
      for (let i = 0; i < this.selectionsgArr.length; i++) {
        // let obj = {
        //   id: this.selectionanArr[i],
        //   status:"0"
        // }
        savelist +=
          '{"id":' + this.selectionsgArr[i] + ',"' + edittype + '":"0"}';
      }
      savelist = "[" + savelist + "]";
      // let obj={
      //   data:savelist
      // }
      alarmApi.updateAlarmType(savelist,this.strordertype).then((res) => {
        //console.log(res)
        //console.log(res.data)
        if (res.code == "0") {
          Message.success({
            message: "修改成功",
          });
        } else {
          Message.error({
            message: res.msg,
          });
        }
      });
    },
    timeFormate(val) {
      if (val) {
        return formatTimeToStr(val);
      } else {
        return "0000-00-00 00:00:00";
      }
    },
    isCarStatus(v) {
      return isCarStatus(v);
    },
    isCarType(v) {
      return isCarType(v);
    },
    closeDialog() {
      this.$emit("closeWindow");
    },
  },
};
</script>
<style scoped>
.user_info {
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  align-items: flex-start;
}
.user_name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #2c2c2c;
  font-weight: bold;
}
.user_name span {
  margin-top: 10px;
}
.user_detail {
  display: flex;
  flex-direction: column;
  /*margin-left: 20px;*/
  flex: 1;
}
.item {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.item div:nth-child(2n-1) {
  color: #8c939d;
  width: 60px;
  text-align: right;
}
.item div:nth-child(2n) {
  color: #2c2c2c;
  font-size: 18px;
  margin-left: 20px;
  margin-right: 20px;
  min-width: 200px;
}
</style>
<template>
  <rv-dialog :title="title" @close="close">
    <el-form
      ref="form"
      :model="menuForm"
      :rules="menuRules"
      label-width="80px"
      :inline="true"
    >
      <el-form-item label="菜单名称" prop="menuName">
        <el-input
          v-model="menuForm.menuName"
          placeholder="请填写菜单名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="菜单图标" prop="icon">
        <el-input
          v-model="menuForm.icon"
          placeholder="请填写菜单图标"
        ></el-input>
      </el-form-item>
      <el-form-item label="菜单地址" prop="component">
        <el-input
          v-model="menuForm.component"
          placeholder="请填写菜单地址"
        ></el-input>
      </el-form-item>
      <el-form-item label="路由地址" prop="path">
        <el-input
          v-model="menuForm.path"
          placeholder="请填写路由地址"
        ></el-input>
      </el-form-item>
      <el-form-item label="上级目录" prop="parentId">
        <el-select v-model="menuForm.parentId" placeholder="请选择上级目录">
          <el-option
            v-for="item in parentList"
            :key="item.menuId"
            :label="item.menuName"
            :value="item.menuId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="显示顺序" prop="orderNum">
        <el-input-number
          v-model="menuForm.orderNum"
          :min="0"
          :controls="false"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="菜单类型" prop="menuType">
        <el-select v-model="menuForm.menuType" placeholder="请选择菜单类型">
          <el-option
            v-for="item in menuTypeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否隐藏" prop="visible">
        <el-radio v-model="menuForm.visible" label="1">是</el-radio>
        <el-radio v-model="menuForm.visible" label="0">否</el-radio>
      </el-form-item>
    </el-form>
    <div class="option-buttons">
      <el-button type="warning" icon="el-icon-plus" @click="addMenu"
        >保存</el-button
      >
      <el-button type="primary" @click="close">取消</el-button>
    </div>
  </rv-dialog>
</template>

<script>
import { menuAdd, menuEdit, menuQueryList } from "@/api/system/menu";
export default {
  data() {
    return {
      dialogState: true,
      title: "新增菜单",
      isUpdate: false,
      menuForm: {
        menuName: "",
        parentId: "",
        orderNum: "",
        path: "",
        component: "",
        visible: "0",
        menuType: "",
        icon: "",
      },
      menuRules: {
        menuName: [
          { required: true, message: "请填写菜单名称", trigger: "blur" },
        ],
        icon: [{ required: true, message: "请填写菜单图标", trigger: "blur" }],
        parentId: [
          { required: true, message: "请选择上级目录", trigger: "change" },
        ],
        orderNum: [
          { required: true, message: "请填写显示顺序", trigger: "blur" },
        ],
        path: [{ required: true, message: "请填写菜单地址", trigger: "blur" }],
        component: [
          { required: true, message: "请填写路由地址", trigger: "blur" },
        ],
        visible: [
          { required: true, message: "请选择是否显示", trigger: "change" },
        ],
        menuType: [
          { required: true, message: "请选择菜单类型", trigger: "change" },
        ],
      },
      menuTypeList: [
        {
          label: "目录",
          value: "M",
        },
        {
          label: "菜单",
          value: "C",
        },
        {
          label: "按钮",
          value: "F",
        },
      ],
      parentList: [
        {
          menuName: "根目录",
          menuId: "0",
        },
      ],
    };
  },
  methods: {
    addMenu() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.isUpdate) {
            menuEdit(this.menuForm).then((res) => {
              if (res.code == 0) {
                this.$message({
                  message: "修改成功",
                  type: "success",
                });
                this.$emit("close", true);
              }
            });
          } else {
            menuAdd(this.menuForm).then((res) => {
              if (res.code == 0) {
                this.$message({
                  message: "添加成功",
                  type: "success",
                });
                this.$emit("close", true);
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    close() {
      this.$emit("close");
    },
    initData(e) {
      this.isUpdate = true;
      this.title = "修改菜单";
      this.menuForm = JSON.parse(JSON.stringify(e));
    },
    getAllMenu() {
      menuQueryList({}).then((res) => {
        if (res.code == 0) {
          this.parentList = this.parentList.concat(res.result);
        }
      });
    },
  },
  mounted() {
    this.getAllMenu();
  },
};
</script>

<style scoped>
.el-dialog__header {
  background: rgb(26, 36, 62);
  display: flex;
}
.dialog_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  color: #fff;
}
.option-buttons {
  display: flex;
  justify-content: center;
}
::v-deep .el-input-number {
  width: 100%;
  text-align: left;
}
::v-deep .el-input__inner {
  text-align: left;
}
::v-deep .el-select {
  width: 177px;
}
</style>
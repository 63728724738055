<template>
  <div class="sidebar">
    <el-menu :default-active="activeMenu" class="el-menu-vertical" :collapse="isCollapse" background-color="rgb(12,21,46)"
      text-color="#fff" active-text-color="rgb(240,200,47)" @select="handleSelect" router>
      <el-menu-item :index="item.path" v-for="item in submenuList" :key="item.menuId">
        <i :class="item.icon + ' iconfont '"></i>
        <span slot="title">{{ item.menuName }}</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>
<script>
export default {
  data() {
    return {
      submenuList: [],
      isCollapse: false,
    };
  },
  computed: {
    activeMenu() {
      const route = this.$route;
      const { path } = route;
      return path;
    },
    activeId() {
      return this.$store.state.user.menuActive;
    },
  },
  watch: {
    activeId: {
      handler(val) {
        if (val !== "") {
          let list = this.$store.state.permission.menuList.find(
            (i) => val == i.menuId
          ).sysMenus;

          this.submenuList = list;
          this.$router.push(list[0].path);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    handleSelect() { },
  },
};
</script>
<style scoped>
.sidebar {
  display: block;
  position: absolute;
  left: 0;
  top: 52px;
  bottom: 0;
  /*background: rgb(12,21,46);*/
  /*width: 220px;*/
}

.el-menu-vertical:not(.el-menu--collapse) {
  width: 220px;

  height: 100%;
}

.el-menu-item:hover {
  background-color: rgb(123 95 95) !important;
}

.el-menu--collapse {
  height: 100%;
}

.iconfont {
  margin-right: 20px;
}

.menu_item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 60px;
  border-bottom: 1px solid rgb(26, 32, 71);
  color: #fff;
  font-size: 16px;
  align-items: center;
}

.menu_item:hover {
  background: rgb(31, 37, 82);
}

.selectMenu {
  background: rgb(26, 36, 62);
  color: rgb(240, 200, 47);
}

.lineActive {
  height: 100%;
  width: 10px;
  background: rgb(240, 200, 47);
}

.lineDisable {
  width: 10px;
}

.menu_name {
  flex: 1;
  display: flex;
  flex-direction: row;
  padding-left: 40px;
  align-items: center;
}

.menu_name span {
  margin-left: 20px;
}
</style>
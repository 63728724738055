<template>
  <div class="equip_wrap">
    <div class="filter_list">
      <!--    <el-input v-model="searchOption.name" placeholder="设备标识码" clearable-->
      <!--              class="handle-input mr10"/>-->
      <!--    <el-button type="primary" icon="el-icon-search" @click="handleSearch" class="ml20 mt10 searchBtn"/>-->
      <!--    <el-button type="primary" icon="el-icon-plus" @click="bindEquipVisible=true" class="ml20 mt10 searchBtn"/>-->
    </div>
    <div class="data_list">
      <el-table :data="tableData" highlight-current-row :style="'width: 100%;overflow-y:auto;margin-top:-10px'"
        :header-cell-style="{ background: '#FFF', color: '#212121' }">
        <el-table-column type="index" label="序号" align="left" :index="indexMethod" width="50" />

        <el-table-column prop="sendTime" label="报警时间" align="left" />
        <el-table-column label="报警内容" align="left" width="400">
          <template slot-scope="scope">
            <div>
              <span>{{
                formatAlarm(
                  scope.row.typeAlarm,
                  scope.row.warnName,
                  scope.row.fenceName,
                  scope.row.macid,
                  scope.row.entity_factory
                )
              }}</span>,
              <!-- <span>{{scope.row.warnName}}</span> -->
              <span>{{ scope.row.sendTime }}</span>
            </div>
          </template>
        </el-table-column>
        <!--<el-table-column  prop="entityNumber" label="报警内容" align="left" />
    <el-table-column label="报警时间" align="left" >
      <template slot-scope="scope">
        <span>{{formateEquipType(scope.row.entityType)}}</span>
      </template>
    </el-table-column> -->

        <el-table-column label="备注" align="left">
          <template slot-scope="scope">
            <span>{{ scope.row.remark }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="insertTime" label="备注时间" align="left" />
        <el-table-column prop="handleBy" label="操作者" align="left" />
        <!-- <el-table-column label="操作" align="left">
      <template slot-scope="scope">
        <el-button type="text" @click="deleteBind(scope.row.entityNumber,scope.row.entityFactory)">解绑</el-button>
      </template>
    </el-table-column> -->
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination background @current-change="handleCurrentChange" layout="prev, pager, next,total"
        :total="searchOption.totalCount" :page-size="10">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import monitor from "@/api/monitor";
//import {Message} from 'element-ui'
import { formatAlarmType, formatAlarm } from "@/api/orderview";
//import {formatFactory} from '../api/carview'//equipTypeFormat
export default {
  data() {
    return {
      bindEquipVisible: false,
      searchOption: {
        name: "",
        pageSize: "10",
        totalCount: 1,
        pageNum: 1,
        orderSn: "",
        vehicleSn: "",
        userName: "",
        warnType: "",
        city: "",
        status: "",
        clearStatus: "",
        orderStatus: "",
        cur_page: 1,
        autoload: "30",
      },
      tableData: [],
    };
  },
  props: {
    orderNum: String,
  },
  comments: {},
  mounted() {
    this.loadData();
  },
  methods: {
    handleSearch() {
      this.loadData();
    },
    loadData() {
      let param = {
        pageNum: this.searchOption.pageNum,
        pageSize: this.searchOption.pageSize,
        orderNumber: this.orderNum,
      };
      monitor.getHisAlarmHistoryList(param).then((res) => {
        if (res.code == 0) {
          this.tableData = res.result.list;
          this.searchOption.totalCount = res.result.total;
        }
      });
    },
    formatAlarmType(v) {
      return formatAlarmType(v);
    },
    formatAlarm(typeAlarm, warnName, fenceName, macid, entity_factory) {
      return formatAlarm(typeAlarm, warnName, fenceName, macid, entity_factory);
    },
    // deleteBind(number,facID){
    //   let param = {
    //     fenceId:this.fenceId,
    //     entityNumber:number,
    //     entityFactory:facID
    //   }
    //   equipApi.removeBind(param).then(res=>{
    //     if(res.code == 0) {
    //       this.$emit('success')
    //     }else{
    //       Message.error({
    //         message: res.msg,
    //       })
    //     }
    //   })
    // },
    handleCurrentChange(val) {
      this.searchOption.pageNum = val;
      this.loadData();
    },
    indexMethod(index) {
      let curPage = this.searchOption.pageNum;
      let pageSize = this.searchOption.pageSize;
      return index + 1 + (curPage - 1) * pageSize;
    },
  },
};
</script>
<style scoped>
.equip_wrap {
  display: flex;
  flex-direction: column;
}

.filter_list {
  display: flex;
  flex-direction: row;
}

.searchBtn {
  height: 30px;
  background: #3b7bcf;
}

.handle-input {
  width: 200px;
  display: inline-block;
}

.dialog_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  color: #fff;
}
</style>
<style less>
.el-dialog__header {
  background: rgb(26, 36, 62);
  display: flex;
}
</style>
<template>
  <div class="chart">
    <div id="warnline" class="myChart"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  data() {
    return{
      option: {
        tooltip: {              //设置tip提示
          trigger: 'axis'
        },
        legend: {
          data:['当日报警数量']
        },
        color: ['#303F9F'],
        xAxis: {                //设置x轴
          type: 'category',
          boundaryGap: false,     //坐标轴两边不留白
          data: ['00:00','01:00','02:00','03:00','04:00','05:00'],
          nameTextStyle: {        //坐标轴名称的文字样式
            color: '#303F9F',
            fontSize: 16,
            padding: [0, 0, 0, 20]
          },
          axisLine: {             //坐标轴轴线相关设置。
            lineStyle: {
              color: '#303F9F',
            }
          }
        },
        yAxis: {
          name: '告警数量',
          nameTextStyle: {
            color: '#303F9F',
            fontSize: 16,
            padding: [0, 0, 10, 0]
          },
          axisLine: {
            lineStyle: {
              color: '#303F9F',
            }
          },
          type: 'value'
        },
        series: [
          {
            name: '当日报警数量',
            data:  [220, 232, 201, 234, 290, 230],
            type: 'line',
            smooth: true,
            // symbol: 'none',
          }
        ]
      }
    }
  },
  props:{
    chartData:Array
  },
  components: {
  },
  mounted() {

  },
  methods:{
    drawLine(data){
      let chartDom = document.getElementById('warnline');
      let myChart = echarts.init(chartDom)
      this.option.xAxis.data = []
      this.option.series[0].data = []
      for(let i=0;i<data.length;i++){
        let d = data[i]
        this.option.xAxis.data.push(d.name)
        this.option.series[0].data.push(d.value)
      }
      this.option && myChart.setOption(this.option)
    }
  }
}
</script>
<style scoped>
  .chart{
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .myChart{
    width: 100%;
    height: 100%;
  }
</style>
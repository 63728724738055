<template>
  <div ref="page" class="data_wrap">
    <div class="search_cond" ref="search_cond">
      <el-input v-model="searchOption.orderSn" placeholder="订单编号" class="handle-input mr10 mt10"></el-input>

      <el-input v-model="searchOption.vehicleSn" placeholder="车牌号" class="handle-input mr10 mt10"></el-input>

      <el-input v-model="searchOption.carName" placeholder="车辆名称" class="handle-input mr10 mt10"></el-input>

      <el-input v-model="searchOption.carVin" placeholder="车架号" class="handle-input mr10 mt10"></el-input>

      <el-input v-model="searchOption.userName" placeholder="用户姓名" class="handle-input mr10 mt10"></el-input>

      <el-select v-model="searchOption.city" class="handle-select mr10 mt10" placeholder="城市" clearable>
        <el-option v-for="item in cities" :key="item.id" :label="item.name" :value="item.name">
          <span style="float: left">{{ item.name }}</span>
          <span style="float: right; color: #8492a6; font-size: 13px">{{
            item.name
          }}</span>
        </el-option>
      </el-select>
      <el-select v-model="searchOption.orderStatus" class="handle-select mr10 mt10" placeholder="订单状态" clearable>
        <el-option key="1" label="确认取车" value="1"></el-option>
        <el-option key="2" label="确认还车" value="2"></el-option>
        <el-option key="3" label="订单取消" value="3"></el-option>
      </el-select>
      <span class="ml10">下单时间：</span>
      <el-date-picker v-model="orderTime" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至"
        start-placeholder="开始日期" end-placeholder="结束日期">
      </el-date-picker>
      <span class="ml10">取车时间：</span>
      <el-date-picker v-model="realPickTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" range-separator="至"
        start-placeholder="开始日期" end-placeholder="结束日期">
      </el-date-picker>
      <span class="ml10">预计还车时间：</span>
      <el-date-picker v-model="planGiveTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" range-separator="至"
        start-placeholder="开始日期" end-placeholder="结束日期">
      </el-date-picker>

      <el-select v-model="searchOption.isFocus" class="handle-select ml10 mr10 mt10" placeholder="重点关注" clearable>
        <el-option key="0" label="未关注" value="0"></el-option>
        <el-option key="1" label="重点关注" value="1"></el-option>
      </el-select>
      <el-select v-model="searchOption.isMortgage" class="handle-select mr10 mt10" placeholder="抵押车辆" clearable>
        <el-option key="0" label="未抵押" value="0"></el-option>
        <el-option key="1" label="抵押车辆" value="1"></el-option>
      </el-select>
      <el-select v-model="searchOption.isControl" class="handle-select mr10 mt10" placeholder="疫情管控" clearable>
        <el-option key="0" label="未管控" value="0"></el-option>
        <el-option key="1" label="疫情管控" value="1"></el-option>
      </el-select>

      <el-select v-model="searchOption.isPriorityReminder" class="handle-select mr10 mt10" placeholder="优先提醒" clearable>
        <el-option key="0" label="未提醒" value="0"></el-option>
        <el-option key="1" label="优先提醒" value="1"></el-option>
      </el-select>

      <el-button type="primary" icon="el-icon-search" @click="handleSearch" class="ml20 mt10 searchBtn">搜索</el-button>
      <el-button type="success" @click="ExcelEntity()" class="cell_btn iconfont icon-daochu">导出</el-button>
    </div>
    <div class="data_list">
      <el-table ref="table" :data="tableData" :height="contentHeight - condHeight - 70"
        :header-cell-style="{ background: '#FFF', color: '#212121' }">
        <el-table-column type="index" label="序号" align="left" :index="indexMethod" width="50" />
        <el-table-column label="订单编号-原订单编号" align="left" width="150">
          <template slot-scope="scope">
            <!-- <span>[{{ formatOrderType(scope.row.orderType) }}]</span> -->
            <span v-if="scope.row.isMortgage == 1"> [抵押车辆] </span>
            <span v-if="scope.row.isControl == 1"> [疫情管控] </span>
            <span v-if="scope.row.isFocus == 1"> [重点关注] </span>
            <div class="label_link" type="text" @click="orderEdit(scope.row.orderNumber)" style="user-select: unset">
              {{ scope.row.orderNumber }}
            </div>
            <div>{{ scope.row.preNumber }}</div>
          </template>
        </el-table-column>
        <el-table-column label="城市" align="left">
          <template slot-scope="scope">
            <div>{{ scope.row.orderCity }}</div>
          </template>
        </el-table-column>
        <el-table-column label="车牌号" align="left" width="100">
          <template slot-scope="scope">
            <div class="label_link" type="text" @click="selectCar(scope.row.carVin)" style="user-select: unset">
              {{ scope.row.carNumber }}
            </div>

            <div v-if="scope.row.isPriorityReminder == 1">[优]</div>
            <div v-if="scope.row.isLockCarnumber == 1">
              [锁]
            </div>

          </template>
        </el-table-column>
        <el-table-column label="车辆名称" align="left">
          <template slot-scope="scope">
            <div>
              {{ scope.row.carName }}

              <span v-if="scope.row.isFirstLease == 1"  style="color: red;"> [首] </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="车架号" align="left">
          <template slot-scope="scope">
            <div>{{ scope.row.carVin }}</div>
          </template>
        </el-table-column>
        <el-table-column label="客户姓名" align="left">
          <template slot-scope="scope">
            <div>
              {{ scope.row.orderUser }}
              <span v-if="scope.row.isFirstApplication == 1" style="color: red;"> [首] </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="身份信息" align="left">
          <template slot-scope="scope">
            <div>{{ scope.row.identityInformation }}</div>
          </template>
        </el-table-column>
        <el-table-column label="订单状态" align="left">
          <template slot-scope="scope">
            <div v-if="scope.row.orderStatus == 1">确认取车</div>
            <div v-else-if="scope.row.orderStatus == 2">确认还车</div>
            <div v-else-if="scope.row.orderStatus == 3">订单取消</div>
          </template>
        </el-table-column>
        <el-table-column label="订单时间" align="left" width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.orderTime }}</span>
          </template>
        </el-table-column>
        <el-table-column label="取车时间" align="left" width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.realPickTime }}</span>
          </template>
        </el-table-column>

        <el-table-column label="预计还车时间" align="left" width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.planGiveTime }}</span>
          </template>
        </el-table-column>
        <el-table-column label="设备号" align="left">
          <template slot-scope="scope">
            <div>
              [{{ factoryFormat(scope.row.entityFactory) }}]
              <span>{{ scope.row.entityNumber }}</span>
            </div>
            <div v-if="scope.row.troubleStatus == 1">[故障]</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination background @size-change="handleSizeChange" :current-page.sync="searchOption.cur_page"
        :page-sizes="[10, 20, 30, 50, 100]" @current-change="handleCurrentChange" layout="prev, pager, next,total,sizes"
        :total="searchOption.totalCount" :page-size="20">
      </el-pagination>
    </div>

    <!--      订单查看-->
    <el-dialog :visible.sync="orderEditVisible" v-if="orderEditVisible" :show-close="false" class="nopadding" width="60%">
      <div slot="title" class="dialog_header">
        <span>订单查看</span>
        <i class="el-icon-close" style="font-size: 26px" @click="orderEditVisible = false" />
      </div>
      <OrderEdit :isCheck="true" :orderNumber="selectOrderNumber" @closeWindow="orderEditVisible = false" />
    </el-dialog>

    <!--      车辆信息-->
    <el-dialog :visible.sync="carInfoVisible" v-if="carInfoVisible" :show-close="false" width="60%">
      <div slot="title" class="dialog_header">
        <span>车辆信息</span>
        <i class="el-icon-close" style="font-size: 26px" @click="carInfoVisible = false" />
      </div>
      <CarInfo :carInfoObj="carInfo" />
    </el-dialog>
  </div>
</template>
<script>
import { queryTroubleList } from "@/api/statistics/equipmentFailure";
import { Message } from "element-ui";
import fenceApi from "@/api/fence";
import monitor from "@/api/monitor";
import {
  formatOrderType,
  formatAlarmType,
  formatAlarm,
  isOrderhandleStatuss,
} from "@/api/orderview";
import OrderEdit from "@/components/OrderEdit";
import CarInfo from "@/components/CarInfo";
import { export2File } from "@/api/exp2file";
//import {formatFactory} from '../api/carview'//equipTypeFormat

export default {
  data() {
    return {
      contentHeight: 0,
      condHeight: 0,
      searchOption: {
        orderSn: "",
        vehicleSn: "",
        userName: "",
        city: "",
        orderStatus: "1",
        pageSize: 20,
        cur_page: 1,
        totalCount: 0,
        carName: "",
        carVin: "",
        isMortgage: "",
        isControl: "",
        isPriorityReminder: "",
      },
      orderTime: [],
      realPickTime: [],
      planGiveTime: [],
      tableData: [],
      pageHeight: 0,
      selectId: "",
      checkedClearHours: 0,
      checkedClearType: 0,
      selectOrerId: "",
      cities: [],
      loading: true,
      selectOrderNumber: "",
      orderEditVisible: false,
      carInfoVisible: false,
      carInfo: {},
    };
  },
  components: { OrderEdit, CarInfo },
  watch: {
    // eslint-disable-next-line no-unused-vars
  },
  updated() { },
  mounted() {
    let date = this.format(new Date());
    this.planGiveTime = [`${date} 00:00:00`, `${date} 23:59:59`];
    this.$nextTick(() => {
      this.contentHeight = this.$refs.page.offsetHeight;
      this.condHeight = this.$refs.search_cond.offsetHeight;
      this.pageHeight = document.documentElement.clientHeight;
      fenceApi.findCitys().then((res) => {
        if (res.code == 0) {
          this.cities = res.result;
        }
      });
      this.loadData();
    });
  },
  methods: {
    ExcelEntity() {
      let param = {
        orderNumber: this.searchOption.orderSn,
        carNumber: this.searchOption.vehicleSn,
        orderUser: this.searchOption.userName,
        orderCity: this.searchOption.city,
        orderStatus: this.searchOption.orderStatus,
        orderType: this.searchOption.orderType,
        isFocus: this.searchOption.isFocus,
        orderTimeStart:
          this.orderTime && this.orderTime.length > 1 ? this.orderTime[0] : "", // 下单时间开始
        orderTimeEnd:
          this.orderTime && this.orderTime.length > 1 ? this.orderTime[1] : "", // 下单时间结束
        realPickTimeStart:
          this.realPickTime && this.realPickTime.length > 1
            ? this.realPickTime[0]
            : "", // 取车时间开始
        realPickTimeEnd:
          this.realPickTime && this.realPickTime.length > 1
            ? this.realPickTime[1]
            : "", // 取车时间结束
        planGiveTimeStart:
          this.planGiveTime && this.planGiveTime.length > 1
            ? this.planGiveTime[0]
            : "", // 计划还车时间开始
        planGiveTimeEnd:
          this.planGiveTime && this.planGiveTime.length > 1
            ? this.planGiveTime[1]
            : "", // 计划还车时间结束,
        isMortgage: this.searchOption.isMortgage,
        isControl: this.searchOption.isControl,
        isPriorityReminder: this.searchOption.isPriorityReminder,
      };

      let myObj = {
        url: process.env.VUE_APP_BASE_API + "/entity/exportTroubleList",
        fileName: "设备故障",
        data: param,
      };
      export2File(myObj);
    },
    factoryFormat(e) {
      if (e == 1) {
        return "四海行";
      } else if (e == 2) {
        return "艾能";
      } else if (e == 3) {
        return "自有硬件";
      } else if (e == 4) {
        return "中瑞";
      } else if (e == 5) {
        return "赛格";
      } else {
        return "";
      }
    },
    selectCar(vin) {
      monitor.getCarContent(vin).then((res) => {
        if (res.code == 0) {
          this.carInfo = res.result;
          this.carInfoVisible = true;
        } else {
          Message.error({
            message: res.msg,
          });
        }
      });
    },
    orderEdit(v) {
      //订单修改
      this.selectOrderNumber = v;
      this.orderEditVisible = true;
    },
    format(dat) {
      //获取年月日，时间
      var year = dat.getFullYear();
      var mon =
        dat.getMonth() + 1 < 10
          ? "0" + (dat.getMonth() + 1)
          : dat.getMonth() + 1;
      var data = dat.getDate() < 10 ? "0" + dat.getDate() : dat.getDate();

      var newDate = year + "-" + mon + "-" + data;
      return newDate;
    },
    loadData() {
      this.loading = true;
      let param = {
        pageNum: this.searchOption.cur_page,
        pageSize: this.searchOption.pageSize,
        orderNumber: this.searchOption.orderSn,
        carNumber: this.searchOption.vehicleSn,
        orderUser: this.searchOption.userName,
        orderCity: this.searchOption.city,
        orderStatus: this.searchOption.orderStatus,
        orderType: this.searchOption.orderType,
        isFocus: this.searchOption.isFocus,
        orderTimeStart:
          this.orderTime && this.orderTime.length > 1 ? this.orderTime[0] : "", // 下单时间开始
        orderTimeEnd:
          this.orderTime && this.orderTime.length > 1 ? this.orderTime[1] : "", // 下单时间结束
        realPickTimeStart:
          this.realPickTime && this.realPickTime.length > 1
            ? this.realPickTime[0]
            : "", // 取车时间开始
        realPickTimeEnd:
          this.realPickTime && this.realPickTime.length > 1
            ? this.realPickTime[1]
            : "", // 取车时间结束
        planGiveTimeStart:
          this.planGiveTime && this.planGiveTime.length > 1
            ? this.planGiveTime[0]
            : "", // 计划还车时间开始
        planGiveTimeEnd:
          this.planGiveTime && this.planGiveTime.length > 1
            ? this.planGiveTime[1]
            : "", // 计划还车时间结束,
        isMortgage: this.searchOption.isMortgage,
        isControl: this.searchOption.isControl,
        isPriorityReminder: this.searchOption.isPriorityReminder,
      };
      queryTroubleList(param).then((res) => {
        if (res.code == 0) {
          this.tableData = res.result.list;
          this.searchOption.totalCount = res.result.total;
        } else {
          Message.error({
            message: res.msg,
          });
        }
        this.$nextTick(() => {
          if (this.$refs.table) {
            this.$refs.table.doLayout();
          }
        });
        this.loading = false;
      });
    },
    handleSearch() {
      this.loadData();
    },
    handleCurrentChange(val) {
      this.searchOption.cur_page = val;
      this.loadData();
    },
    handleSizeChange(val) {
      this.searchOption.pageSize = val;
      this.loadData();
    },

    formatAlarmType(v) {
      return formatAlarmType(v);
    },
    formatAlarm(typeAlarm, warnName, fenceName, macid, entity_factory) {
      return formatAlarm(typeAlarm, warnName, fenceName, macid, entity_factory);
    },
    formatOrderType(v) {
      return formatOrderType(v);
    },
    isOrderhandleStatuss(v) {
      return isOrderhandleStatuss(v);
    },
    indexMethod(index) {
      let curPage = this.searchOption.cur_page;
      let pageSize = this.searchOption.pageSize;
      return index + 1 + (curPage - 1) * pageSize;
    },
  },
};
</script>
<style lang="scss" scoped>
.data_wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.handle-input {
  width: 150px;
  display: inline-block;
}

.handle-select {
  width: 160px;
}

.searchBtn {
  height: 30px;
  background: #3b7bcf;
}

.label_link {
  color: #3b7bcf;
  text-decoration: underline;
  cursor: pointer;
}

.label_link：hover {
  cursor: pointer;
}

.verticalCol {
  display: flex;
  flex-direction: column;
}

.verticalRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.myDrawer {
  width: 60%;
}

.dialog_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  color: #fff;
}

.verticalRow span {
  margin-right: 4px;
}

.editIcon {
  color: #3b7bcf;
  cursor: pointer;
}

.clear_wrap {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.clear_setting {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  font-weight: bold;
}

.clearBtn {
  width: 60px;
}

.pagination {
  height: 70px;
}

// .search_cond {
//   /* height: 100px; */
// }
.data_list {
  width: 100%;
  flex: 1;
  margin-top: 10px;
}
</style>
<style less>
.el-dialog__header {
  background: rgb(26, 36, 62);
  display: flex;
}

.nopadding .el-dialog__body {
  padding: 0px;
}

.el-table::before {
  z-index: inherit;
}

.el-table .notice-row {
  background: #ff5151;
}

.el-table .light-row {
  background: #f0f9eb;
}

.dialogFullSc .el-dialog__body {
  height: calc(100% - 60px);
  width: 100%;
}

.dialogLessSc .el-dialog__body {
  height: 500px;
  width: 100%;
}

.cursor {
  cursor: pointer;
}

.label_link {
  color: #3b7bcf;
  text-decoration: underline;
  cursor: pointer;
}

.label_link：hover {
  cursor: pointer;
}
</style>

import { get, post } from './request'

export default {

    /**
     * 获取报警数量
     * @param params
     * @returns {Promise | Promise<unknown>}
     */
    getWarnCount() {
        return post('dataInterface/getAlarmCount')
    },
    /**
     * 获取每小时的报警数量 
     */
    getWarnCount4Hour() {
        return post('dataInterface/getCurrentAlarmList')
    },
    /**
     * 获取监控中订单数量
     * @returns {Promise | Promise<unknown>}
     */
    getOrderCount() {
        return get('dataInterface/orderMonitor')
    },

    /**
     * 获取厂商接口对接状态
     * @returns {Promise<unknown>}
     */
    getConnectStatus() {
        return get('dataInterface/getSystemList')
    },
    /**
 * 获取报警数量监控情况
 * @returns {Promise<unknown>}
 */
    getAlarmCountList(param) {
        return get('dataInterface/getAlarmCountList', param)
    },
    /**
* 获取地图渲染数据
* @returns {Promise<unknown>}
*/
    getGeoJson(param) {
        return get('dataInterface/getGeoJson', param)
    }
}

<template>
  <div :class="['tip-layout', { 'close': close }]" :style="layoutStyle">
    <div class="tip-layout-control" @click="close = !close">
      <i class=" el-icon-warning-outline i-icon" v-if="close"></i>
      <i class="el-icon-close i-icon" v-else></i>
    </div>
    <div class="tip-layout-wrapper">
      <div class="tip-layout-wrapper-title">{{ title }}</div>
      <div class="tip-layout-wrapper-content">
        <slot></slot>
        <div v-for="(item, idx) in content" :key="idx">
          <span>{{ idx + 1 }}.{{ item }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  props: {
    //标题
    title: {
      type: String,
      default: "操作提示"
    },
    //提示内容
    content: Array,
    //距离顶部高度
    top: {
      type: Number,
      default: 160
    }
  },
  data() {
    return {
      close: true
    };
  },
  computed: {
    layoutStyle() {
      return {
        top: `${this.top - (this.fixedTabs ? 64 : 0)}px`
      };
    }
  }
};
</script>

<style scoped>
.tip-layout {
  user-select: none;
  position: fixed;
  right: 0;
  z-index: 100;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all 0.3s;
  width: 600px;
  transform: translateX(0);
}

.tip-layout::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 40px;
  background: white;
  content: " ";
  display: block;

}

.close {
  transform: translateX(calc(100% - 40px));
}

.tip-layout-control {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 5px 0 0 5px;
  background: #3b7bcf;
  color: #3b7bcf;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: -2px 0 8px rgb(0 0 0 / 15%);

}

.i-icon {
  font-size: 20px;
  color: #ffffff;
}

.flex-center {
  font-size: 22px;
}


.tip-layout-wrapper {
  background: white;
  flex: 1;
  border: 1px dashed #3b7bcf;
  box-sizing: border-box;
  user-select: none;
  background: fade(#3b7bcf, 10%);
  padding: 10px;
  font-size: 12px;
  z-index: 101;


}

.tip-layout-wrapper-title {
  color: #3b7bcf;
  font-weight: bold;
  margin-bottom: 4px;
}
</style>

import { get, post, postByForm, deleteType } from './request'

export default {
    /**
     * 判断订单号是否存在
     * @returns {Promise | Promise<unknown>}
     */
    checkOrderNumber(no) {
        return post('/carorder/getOrderByOrderNumber?orderNumber=' + no)
    },
    /**
     * 判断原订单号是否存在
     * @returns {Promise | Promise<unknown>}
     */
    checkPreOrderNumber(no) {
        return post('/carorder/getOrderByPreNumber?preNumber=' + no)
    },

    /**
     * 添加订单
     * @returns {Promise | Promise<unknown>}
     */
    add(params) {
        return post('/carorder/orderAdd', params)
    },
    /**
         * 修改订单
         * @returns {Promise | Promise<unknown>}
         */
    edit(params) {
        return post('/carorder/orderEdit', params)
    },

    /**
     * 监控中订单列表
     * @param param
     * @returns {Promise | Promise<unknown>}
     */
    getListInMonitor(param) {
        return post('/carorder/findOrderPage?pageNum='
            + param.pageNum + '&pageSize=' + param.pageSize + '&orderNumber=' + param.orderSn)
    },

    /**
     * 获取订单信息
     * @param param
     * @returns {Promise | Promise<unknown>}
     */
    getOrderContent(param) {
        return post('/carorder/queryOrderByOrderNumber?orderNumber=' + param.sn)
    },

    /**
     * 订单列表
     * @param param
     * @returns {Promise | Promise<unknown>}
     */
    getListInMysql(param) {
        return post('/carorder/findlistOrder?', param)
    },
    /**
         * 搜索订单日志
         * @param param
         * @returns {Promise | Promise<unknown>}
    */
    getListLog(param) {
        return post('/carorderlog/orderLogList?', param)
    },

    /**
     * 订单车辆冲突列表
     * @param param
     * @returns {Promise | Promise<unknown>}
     */
    getCarOrderClashList(param) {
        return post('/carorderclash/queryOrderClashPage?pageNum='
            + param.pageNum + '&pageSize=' + param.pageSize + '&orderNumber=' + param.orderNumber + '&status=' + param.status)
    },
    /**
     * 保存车辆冲突
     * @param param
     * @returns {Promise | Promise<unknown>}
     */
    saveCarOrderClash(id, action) {
        return post('/carorderclash/clashHandle?id='
            + id + '&action=' + action)
    },
    /**
        * 修改订单备注
        * @returns {Promise | Promise<unknown>}
        */
    editRemark(params) {
        return post('/carorder/oderEditRemark', params)
    },
    /**
        * 查看订单备注
        * @returns {Promise | Promise<unknown>}
        */
    ViewRemark(param) {
        return post('/carorder/getOrderRemarkList?pageNum='
            + param.pageNum + '&pageSize=' + param.pageSize + '&orderNumber=' + param.orderNumber)
    },
    /**
    * 现勘文件列表
    */
    orderSurveyFileList(param) {
        return post('/orderSurveyFile/list', param
        )
    },
    /**
* 删除数据
*/
    delOrderSurveyFile(param) {
        return deleteType('/orderSurveyFile/' + param.id, param
        )
    },
    /**
* 保存现勘文件
*/
    addOrderSurveyFile(param) {
        return postByForm('/orderSurveyFile/add', param
        )
    },

    /**
* 根据属地编码获取数据
*/
    queryByCode(param) {
        return get('/cityBd/queryByCode', param
        )
    },

}

import { post, get, postByQuery } from '@/api/request'

//添加区域
export const areaAdd = params => {
    return post('/city/areaAdd', params)
}

//区域列表（分页）
export const areaQueryPage = params => {
    return post('/city/areaQueryPage', params)
}

//区域列表（不分页）
export const areaQueryList = params => {
    return post('/city/areaQueryList', params)
}

//通过ID获取单条数据
export const areaSelectOne = params => {
    return get('/city/areaSelectOne', params)
}

//区域修改
export const areaEdit = params => {
    return post('/city/areaEdit', params)
}

//区域删除
export const areaDelete = params => {
    return postByQuery('/city/areaDelete', params)
}
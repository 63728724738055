import { post, get, postByQuery } from '@/api/request'

//添加城市
export const cityAdd = params => {
    return post('/city/cityAdd', params)
}

//城市列表（分页）
export const cityQueryPage = params => {
    return post('/city/cityQueryPage', params)
}

//城市列表（不分页）
export const cityQueryList = params => {
    return post('/city/cityQueryList', params)
}

//通过ID获取单条数据
export const citySelectOne = params => {
    return get('/city/citySelectOne', params)
}

//城市修改
export const cityEdit = params => {
    return post('/city/cityEdit', params)
}

//城市删除
export const cityDelete = params => {
    return postByQuery('/city/cityDelete', params)
}
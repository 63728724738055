import { post, postByQuery } from './request'

export default {
    /**
     * 条件搜索
     * @param param
     * @returns {Promise | Promise<unknown>}
     */
    getListInMysql(param) {
        return post('/carinfo/searchCarList', param)
    },
    //车辆状态更新
    editCarStatus(param) {
        return postByQuery('/carinfo/editCarStatus', param)
    },
    //设备状态更新
    editEntityStatus(param) {
        return postByQuery('/entity/editEntityStatus', param)
    },
    //车辆是否在库状态更新
    editCarInstock(param) {
        return postByQuery('/carinfo/editCarInstock', param)
    },
    //车辆是否在库状态更新
    editEntityInstock(param) {
        return postByQuery('/entity/editEntityInstock', param)
    },
}
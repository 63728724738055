<template>
  <div ref="page" class="data_wrap">
    <div class="search_cond">
      <el-input
        v-model="searchOption.equipSn"
        placeholder="设备编号"
        class="handle-input mr10 mt10"
        clearable
      ></el-input>
      <el-input
        v-model="searchOption.carNumber"
        placeholder="车牌号"
        class="handle-input mr10 mt10"
        clearable
      ></el-input>
<!-- <el-input v-model="searchOption.equipName" placeholder="设备名称"
class="handle-input mr10 mt10" clearable></el-input> -->
      <el-select
        v-model="searchOption.factoryName"
        class="handle-select mr10 mt10 mb10"
        placeholder="厂家"
        clearable
      >
        <el-option key="1" label="四海行" value="1"></el-option>
        <el-option key="2" label="艾能" value="2"></el-option>
        <el-option key="3" label="自有硬件" value="3"></el-option>
        <el-option key="4" label="中瑞" value="4"></el-option>
        <el-option key="5" label="赛格" value="5"></el-option>
      </el-select>
      <!-- <el-input v-model="searchOption.factoryName" placeholder="厂家"
                class="handle-input mr10 mt10" clearable></el-input> -->

      <el-select
        v-model="searchOption.entityType"
        class="handle-select mr10 mt10"
        placeholder="设备类型"
        clearable
      >
        <el-option key="1" label="OBD" value="1"></el-option>
        <el-option key="2" label="无线" value="2"></el-option>
        <el-option key="3" label="有线" value="3"></el-option>
        <el-option key="4" label="断油电" value="4"></el-option>
        <el-option key="5" label="静默" value="5"></el-option>
      </el-select>
      <el-select
        v-model="searchOption.status"
        class="handle-select mr10 mt10"
        placeholder="设备状态"
        clearable
      >
        <el-option key="1" label="有效" value="1"></el-option>
        <el-option key="2" label="无效" value="2"></el-option>
      </el-select>
      <el-select
        v-model="searchOption.offline"
        class="handle-select mr10 mt10"
        placeholder="是否在线"
        clearable
      >
        <el-option key="2" label="在线" value="2"></el-option>
        <el-option key="1" label="离线" value="1"></el-option>
      </el-select>
      <el-select
        v-model="searchOption.isInstock"
        class="handle-select mr10 mt10"
        placeholder="是否在库"
        clearable
      >
        <el-option key="1" label="在库" value="1"></el-option>
        <el-option key="2" label="出库" value="2"></el-option>
      </el-select>
      <el-select
        v-model="searchOption.carStatus"
        class="handle-select mr10 mt10"
        placeholder="车辆状态"
        clearable
      >
        <el-option key="1" label="运营" value="1"></el-option>
        <el-option key="2" label="下线" value="2"></el-option>
        <el-option key="3" label="采购" value="3"></el-option>
      </el-select>
      <el-select
        v-model="searchOption.troubleStatus"
        class="handle-select mr10 mt10"
        placeholder="故障状态"
        clearable
      >
        <el-option key="0" label="正常" value="0"></el-option>
        <el-option key="1" label="故障" value="1"></el-option>
      </el-select>
      <el-select
        v-model="searchOption.signalType"
        class="handle-select mr10 mt10"
        placeholder="信号类型"
        clearable
      >
        <el-option key="0" label="2G" value="2G"></el-option>
        <el-option key="1" label="4G" value="4G"></el-option>
      </el-select>
      <el-button
        type="primary"
        icon="el-icon-search"
        @click="handleSearch()"
        class="ml20 mt10 searchBtn"
        >搜索</el-button
      >
      <el-button
        type="success"
        @click="ExcelEntity()"
        class="cell_btn iconfont icon-daochu"
        >导出设备</el-button
      >
      <div style="width: 100px;display: inline-block;margin: 0 10px;">
        <el-upload
          :headers="headers"
          :show-file-list="false"
          :data="{
            updateSupport: true,
          }"
          :on-success="uploadSucc"
          :action="`${uploadUrl}/entity/importData`"
        >
          <el-button type="primary" icon="el-icon-upload2" class="searchBtn"
            >导入设备</el-button
          >
        </el-upload>
      </div>

      <el-button
        type="primary"
        icon="el-icon-download"
        @click="templateDown()"
        class="searchBtn"
        >模板下载</el-button
      >
      <el-button
        type="danger"
        icon="el-icon-bottom"
        @click="handleDown()"
        style="height: 30px;"
        >批量下线</el-button
      >
      <el-button
        type="success"
        icon="el-icon-top"
        @click="handleTop()"
        class="cell_btn"
        style="height: 30px;"
        >批量上线</el-button
      >
      <!-- <el-button
        type="danger"
        icon="el-icon-delete"
        @click="toDelete()"
        style="height: 30px;"
        >批量删除</el-button
      > -->
    </div>
    <div class="data_list" style="margin-top: 10px">
      <el-table
        v-loading="isLoading"
        ref="table"
        element-loading-text="数据加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        :data="tableData"
        highlight-current-row
        :height="contentHeight - 120"
        :header-cell-style="{ background: '#FFF', color: '#212121' }"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          type="index"
          label="序号"
          :index="indexMethod"
          align="left"
          width="50"
        />
        <el-table-column label="设备编号" align="left" prop="entityNumber" />
        <!-- <el-table-column label="设备名称" align="left" prop="baiduEntityName"/> -->
        <!-- <el-table-column label="MAC" align="left" prop="entityMac"/> -->
        <el-table-column label="绑定车辆" align="left" min-width="140">
          <template slot-scope="scope">
            <el-button type="text" @click="showCarInfo(scope.row.carVo)">{{
              scope.row.carVo && scope.row.carVo.carNumber
            }}</el-button>
          </template>
        </el-table-column>
        <el-table-column
          label="厂家"
          align="left"
          prop="entityFactory"
          :formatter="manufactoryFormat"
        />
        <el-table-column
          label="设备类型"
          align="left"
          :formatter="equipTypeFormat"
        />
        <el-table-column label="信号类型" align="left" prop="signalType" />
        <el-table-column
          label="设备状态"
          align="left"
          prop="status"
          :formatter="equipStatusFormat"
        >
          <template #default="scoped">
            <div>
              <el-tag
                effect="dark"
                type="success"
                v-if="scoped.row.status == 1"
                @click="changeDevicedStatus(scoped.row)"
              >
                有效
              </el-tag>
              <el-tag
                effect="dark"
                type="info"
                v-if="scoped.row.status == 2"
                @click="changeDevicedStatus(scoped.row)"
              >
                无效
              </el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="是否在线"
          align="left"
          prop="offline"
          :formatter="equipOfflineFormat"
        />
        <el-table-column label="故障状态" align="left" prop="troubleStatus">
          <template #default="scoped">
            <div>
              <el-tag
                effect="dark"
                type="success"
                v-if="scoped.row.troubleStatus == 0"
                @click="changeTroubleStatus(scoped.row, 1)"
              >
                正常
              </el-tag>
              <el-tag
                effect="dark"
                type="info"
                v-if="scoped.row.troubleStatus == 1"
                @click="changeTroubleStatus(scoped.row, 0)"
              >
                故障
              </el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="是否在库"
          align="left"
          prop="isInstock"
          :formatter="equipIsInstockFormat"
        >
          <template #default="scoped">
            <div>
              <el-tag
                effect="dark"
                type="success"
                v-if="scoped.row.isInstock == 1"
                @click="changeCarInstock(scoped.row)"
              >
                在库
              </el-tag>
              <el-tag
                effect="dark"
                type="info"
                v-if="scoped.row.isInstock == 2"
                @click="changeCarInstock(scoped.row)"
              >
                出库
              </el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="最新位置/上报时间" align="left" min-width="140">
          <!-- <template slot-scope="scope">
            <div>{{getEntitylocation(scope.row.entityFactory,scope.row.entityNumber)}}</div>
          </template> -->
          <template slot-scope="scope">
            <div>{{ scope.row.lastLoc }}/{{ scope.row.lastTime }}</div>
          </template>
        </el-table-column>
        <!--        <el-table-column label="操作" align="left">-->
        <!--          <template slot-scope="scope">-->
        <!--            <el-row>-->
        <!--              <el-col :span="12">-->
        <!--                <el-button type="text" icon="el-icon-open" v-if="scope.row.status == 2" class="btnOnline">在线</el-button>-->
        <!--                <el-button type="text" icon="el-icon-open" v-else class="btnOutLine">离线</el-button>-->
        <!--              </el-col>-->
        <!--              <el-col :span="12">-->
        <!--&lt;!&ndash;                <el-button type="text" icon="el-icon-paperclip">绑定围栏</el-button>&ndash;&gt;-->
        <!--              </el-col>-->
        <!--            </el-row>-->
        <!--          </template>-->
        <!--        </el-table-column>-->
        <el-table-column label="操作" align="left" fixed="right" width="180">
          <template slot-scope="scope">
            <el-row>
              <el-col :span="8">
                <el-button
                  type="text"
                  icon="el-icon-location-information"
                  @click="
                    locationEquipment(
                      scope.row.entityNumber,
                      scope.row.entityFactory
                    )
                  "
                  >位置</el-button
                >
              </el-col>
              <el-col :span="8">
                <el-button
                  type="text"
                  icon="el-icon-aim"
                  @click="toTrace(scope.row)"
                  >轨迹</el-button
                >
              </el-col>
              <el-col :span="8">
                <el-button
                  type="text"
                  icon="el-icon-delete"
                  v-if="scope.row.status != 1"
                  @click="toDelete([scope.row])"
                  >删除</el-button
                >
              </el-col>
            </el-row>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination" v-if="isShow">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          layout="prev, pager, next,total"
          :total="searchOption.totalCount"
          :page-size="searchOption.pageSize"
        >
        </el-pagination>
      </div>
    </div>

    <!--      车辆信息-->
    <el-dialog
      :visible.sync="carInfoVisible"
      v-if="carInfoVisible"
      :show-close="false"
      width="60%"
    >
      <div slot="title" class="dialog_header">
        <span>车辆信息</span>
        <i
          class="el-icon-close"
          style="font-size: 26px"
          @click="carInfoVisible = false"
        />
      </div>
      <CarInfo :carInfoObj="carInfo" />
    </el-dialog>

    <el-dialog
      :visible.sync="realTimePosVisible"
      v-if="realTimePosVisible"
      :show-close="false"
      :fullscreen="fullscreen"
      :class="fullscreen ? 'nopadding dialogFullSc' : 'nopadding dialogLessSc'"
    >
      <div slot="title" class="dialog_header">
        <span>车辆实时位置</span>
        <div>
          <i
            class="iconfont icon-zuixiaohua_huaban1"
            style="font-size: 26px"
            @click="fullscreen = false"
          />
          <i
            class="iconfont icon-zuidahua"
            style="font-size: 26px"
            @click="fullscreen = true"
          />
          <i
            class="el-icon-close"
            style="font-size: 26px"
            @click="realTimePosVisible = false"
          />
        </div>
      </div>
      <VehiclePosition
        :equipNumber="selectEquipNumber"
        :equipFactory="selectEquipFactory"
        page="equipList"
      />
    </el-dialog>

    <el-dialog
      :visible.sync="orderTraceVisible"
      v-if="orderTraceVisible"
      :show-close="false"
      class="nopadding"
      :style="'height:' + pageHeight * 0.9 + 'px'"
      width="60%"
    >
      <div slot="title" class="dialog_header">
        <span>车辆轨迹</span>
        <i
          class="el-icon-close"
          style="font-size: 26px"
          @click="orderTraceVisible = false"
        />
      </div>
      <VehicleTrace
        :equipNumber="selectEquipNumber"
        :equipFactory="selectEquipFactory"
        page="equipList"
      />
    </el-dialog>
  </div>
</template>
<script>
import carApi from "@/api/car";
import equipmentApi from "@/api/equipment";
import traceApi from "@/api/trace";
import CarInfo from "@/components/CarInfo";
import VehiclePosition from "@/components/VehiclePosition";
import VehicleTrace from "@/components/VehicleTrace";
import monitor from "@/api/monitor";
import { Message } from "element-ui";
import { export2File } from "@/api/exp2file";
import { getToken } from "@/utils/auth";

export default {
  data() {
    return {
      contentHeight: 0,
      searchOption: {
        equipSn: "",
        equipName: "",
        entityFactory: "",
        status: "",
        pageSize: 10,
        cur_page: 1,
        totalCount: 0,
        offline: "",
        carNumber: "",
        entityType: "",
        isInstock: "",
        carStatus: "",
        troubleStatus: "",
        signalType: "",
      },
      tableData: [],
      realTimePosVisible: false,
      orderTraceVisible: false,
      carInfoVisible: false,
      selectOrderNumber: "",
      selectOrderCity: "",
      selectEquipNumber: "",
      selectEquipFactory: "",
      carInfo: {},
      isLoading: false,
      headers: { Authorization: "Bearer " + getToken("rvToken") },
      uploadUrl: process.env.VUE_APP_BASE_API,
      selectList: [],
      isShow: true,
    };
  },
  components: { CarInfo, VehiclePosition, VehicleTrace },
  updated() {
    this.$nextTick(() => {
      this.$refs.table.doLayout();
    });
  },
  mounted() {
    this.$nextTick(() => {
      this.contentHeight = this.$refs.page.offsetHeight;
    });
    this.loadData();
  },
  methods: {
    handleTop() {
      if (this.selectList.length == 0) {
        this.$message.warning("请选择数据");
        return false;
      }
      this.$confirm("是否上线所选设备?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let ids = this.selectList.map((i) => i.entityId);
        equipmentApi
          .BatchEditStatus({
            entityIds: ids.join(","),
            status: 1,
          })
          .then(() => {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.loadData();
          });
      });
    },
    handleDown() {
      if (this.selectList.length == 0) {
        this.$message.warning("请选择数据");
        return false;
      }
      this.$confirm("是否下线所选设备?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let ids = this.selectList.map((i) => i.entityId);
        equipmentApi
          .BatchEditStatus({
            entityIds: ids.join(","),
            status: 2,
          })
          .then(() => {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.loadData();
          });
      });
    },
    handleSelectionChange(e) {
      this.selectList = e;
    },
    uploadSucc(e) {
      if (e.code != 200) {
        this.$alert("<strong>  e.msg</strong>", "信息", {
          dangerouslyUseHTMLString: true,
          message: e.msg,
          customClass: "myClass",
          showConfirmButton: false,
        });
        // this.$message({
        //   showClose: true,
        //   dangerouslyUseHTMLString: true,
        //   message: e.msg,
        //   type: "warning",
        //  customClass: "myClass",
        // });
      } else {
        this.$message.success(e.data.msg);
        this.searchOption.cur_page = 1;
        this.loadData();
      }
    },
    //导入模版下载
    templateDown() {
      let param = {};

      let myObj = {
        url: process.env.VUE_APP_BASE_API + "/entity/importTemplate",
        fileName: "车辆GPS信息模板",
        data: param,
      };
      export2File(myObj);
    },
    changeTroubleStatus(e, status) {
      console.log(e);
      this.$confirm("是否切换故障状态?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let param = {
          macid: e.entityNumber,
          entity_factory: e.entityFactory,
          troubleStatus: status,
        };
        monitor.editTrouble(param).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.loadData();
          } else {
            Message.error({
              message: res.msg,
            });
          }
        });
      });
    },
    //切换设备状态
    changeDevicedStatus(row) {
      this.$confirm("是否要切换状态?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.isLoading = true;
        carApi
          .editEntityStatus({
            entityId: parseInt(row.entityId),
          })
          .then(() => {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.loadData();
          })
          .catch(() => {
            this.isLoading = false;
          });
      });
    },
    //切换设备出库状态
    changeCarInstock(row) {
      this.$confirm("是否要切换在库状态?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.isLoading = true;
        carApi
          .editEntityInstock({
            entityId: parseInt(row.entityId),
          })
          .then(() => {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.loadData();
          })
          .catch(() => {
            this.isLoading = false;
          });
      });
    },
    handleSearch() {
      this.isShow = false;
      this.searchOption.cur_page = 1;
      this.loadData();
    },
    handleCurrentChange(val) {
      this.searchOption.cur_page = val;
      this.loadData();
    },
    loadData() {
      this.isLoading = true;
      let param = {
        pageNum: this.searchOption.cur_page,
        pageSize: this.searchOption.pageSize,
        entityNumber: this.searchOption.equipSn, //设备号
        entityFactory: this.searchOption.factoryName, //厂家
        status: this.searchOption.status, //设备状态
        carNumber: this.searchOption.carNumber, //车牌号
        offline: this.searchOption.offline, //是否在线
        isInstock: this.searchOption.isInstock, //是否在库
        entityType: this.searchOption.entityType, //设备类型
        carStatus: this.searchOption.carStatus, //车辆状态
        troubleStatus: this.searchOption.troubleStatus, //故障状态
        signalType: this.searchOption.signalType,
      };
      equipmentApi
        .getListInMysql(param)
        .then((res) => {
          if (res.code == 0) {
            this.$refs.table.clearSelection();
            this.selectList = [];
            this.tableData = res.result.list;
            this.searchOption.totalCount = res.result.total;
          }
        })
        .finally(() => {
          this.isLoading = false;
          this.isShow = true;
        });
    },
    /**
     * 导出设备列表
     */
    ExcelEntity() {
      let param = {
        entityNumber: this.searchOption.equipSn, //设备号
        carNumber: this.searchOption.carNumber, //车牌号
        entityFactory: this.searchOption.factoryName, //厂家
        entityType: this.searchOption.entityType, //设备类型
        status: this.searchOption.status, //设备状态
        offline: this.searchOption.offline, //是否在线
        isInstock: this.searchOption.isInstock, //是否在库
        carStatus: this.searchOption.carStatus, //车辆状态
        troubleStatus: this.searchOption.troubleStatus, //故障状态
        signalType: this.searchOption.signalType,
      };

      let myObj = {
        url: process.env.VUE_APP_BASE_API + "/entity/exportEntityList",
        fileName: "设备列表",
        data: param,
      };
      export2File(myObj);
    },
    /**
     * 定位监控的设备
     */
    locationEquipment(v, c) {
      // this.$router.push({path:'/trace',params:{eno:'1111111'}})
      // this.selectEquipNumber = v
      // this.selectEquipFactory = c
      // this.realTimePosVisible = true
      // this.fullscreen = true
      let params = { equipNumber: v, equipFactory: c, page: "equipList" };

      const { href } = this.$router.resolve({
        path: "/position",
        query: params,
      });
      // console.log(href)
      window.open(href, "_blank", "toolbar=yes, width=900, height=700");
    },
    /**
     * 订单轨迹
     */
    toTrace(obj) {
      let params = {
        carNumber: obj.carVo.carNumber,
        pickTime: obj.editTime,
        factory: obj.entityFactory,
      };
      const { href } = this.$router.resolve({
        path: "/trace",
        query: params,
      });
      // console.log(href)
      window.open(href, "_blank", "toolbar=yes, width=900, height=700");

      // this.$router.push({
      //   path: "/property/trace",
      //   query: {
      //     equipId: obj.entityNumber,
      //     pickTime: obj.editTime,
      //     factory: obj.entityFactory,
      //   },
      // });
    },
    toDelete(obj) {
      let list = [];
      if (!obj) {
        if (this.selectList.length == 0) {
          this.$message.warning("请选择数据");
          return false;
        }
        list = this.selectList;
      } else {
        list = obj;
      }
      // if (list.filter((i) => i.status == 1)) {
      //   this.$message.warning("所选设备不可以删除");
      //   return false;
      // }

      this.$confirm("是否删除所选设备?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let ids = list.map((i) => i.entityId);
        equipmentApi
          .BatchDelEntity({
            entityIds: ids.join(","),
          })
          .then(() => {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.loadData();
          });
      });
    },
    getEntitylocation(efactory, enumber) {
      traceApi.getCurrentPos(enumber, efactory).then((res) => {
        //console.log(res);
        if (res.code == 0) {
          console.log(res.result);
          //console.log(res.result.locDesc+"/"+res.result.locTimeStr);
          return res.result.locDesc + "/" + res.result.locTimeStr;
          // this.center = {lng:res.result.longitude, lat:res.result.latitude}
          // this.showInfoWindow = true
          // this.posInfo = res.result
        } else {
          return "实时位置获取失败";
          // Message.error({
          //   message: '实时位置获取失败！实时位置获取失败',
          // })
        }
      });
    },
    equipStatusFormat(row) {
      //status 设备状态 1-使用中 2-无效
      if (row.status == 1) {
        return (
          <el-tag effect="dark" type="success">
            有效
          </el-tag>
        );
      } else if (row.status == 2) {
        return (
          <el-tag effect="dark" type="info">
            无效
          </el-tag>
        );
      } else {
        return <span>--</span>;
      }
    },
    equipOfflineFormat(row) {
      //offline 是否在线，1：离线，2 在线
      if (row.offline == 2) {
        return (
          <el-tag effect="dark" type="success">
            在线
          </el-tag>
        );
      } else if (row.offline == 1) {
        return (
          <el-tag effect="dark" type="info">
            离线
          </el-tag>
        );
      } else {
        return <span>--</span>;
      }
    },
    troubleStatusFormat(row) {
      //offline 是否故障，0：正常，1 故障
      if (row.troubleStatus == 0) {
        return (
          <el-tag effect="dark" type="success">
            正常
          </el-tag>
        );
      } else if (row.troubleStatus == 1) {
        return (
          <el-tag effect="dark" type="info">
            故障
          </el-tag>
        );
      } else {
        return <span>--</span>;
      }
    },
    equipIsInstockFormat(row) {
      //isInstock 是否在库，1：在库，2 出库
      if (row.isInstock == 1) {
        return (
          <el-tag effect="dark" type="success">
            在库
          </el-tag>
        );
      } else if (row.isInstock == 2) {
        return (
          <el-tag effect="dark" type="info">
            出库
          </el-tag>
        );
      } else {
        return <span>--</span>;
      }
    },
    equipTypeFormat(row) {
      if (row.entityType == 1) {
        return <span class="etype">OBD</span>;
      } else if (row.entityType == 3) {
        return <span class="etype">有线</span>;
      } else if (row.entityType == 2) {
        return <span class="etype">无线</span>;
      } else if (row.entityType == 4) {
        return <span class="etype">断油电</span>;
      } else if (row.entityType == 5) {
        return <span class="etype">静默</span>;
      } else {
        return <span>--</span>;
      }
    },
    manufactoryFormat(row) {
      if (row.entityFactory == 1) {
        return <span>四海行</span>;
      } else if (row.entityFactory == 2) {
        return <span>艾能</span>;
      } else if (row.entityFactory == 3) {
        return <span>自有硬件</span>;
      } else if (row.entityFactory == 4) {
        return <span>中瑞</span>;
      } else if (row.entityFactory == 5) {
        return <span>赛格</span>;
      } else {
        return <span>无</span>;
      }
    },
    indexMethod(index) {
      let curPage = this.searchOption.cur_page;
      let pageSize = this.searchOption.pageSize;
      return index + 1 + (curPage - 1) * pageSize;
    },
    showCarInfo(obj) {
      this.carInfoVisible = true;
      this.carInfo = obj;
    },
  },
};
</script>
<style scoped>
.data_wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.handle-input {
  width: 150px;
  display: inline-block;
}

.handle-select {
  width: 120px;
}

.searchBtn {
  height: 30px;
  background: #3b7bcf;
}

.ExcelBtn {
  height: 30px;
  background: #3dd123;
}

.etype {
  font-weight: bold;
  color: #212121;
}

.verticalCol {
  display: flex;
  flex-direction: column;
}

.myDrawer {
  width: 60%;
}

.dialog_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  color: #fff;
}

.btnOnline {
  color: #3b7bcf;
}

.btnOutLine {
  color: #8c939d;
}
</style>
<style less>
.el-dialog__header {
  background: rgb(26, 36, 62);
  display: flex;
}
</style>

<template>
  <div class="chart">
    <div :id="id" class="myChart"></div>
  </div>
</template>
<script>
import * as echarts from "echarts/core";
import { TooltipComponent } from "echarts/components";
import { PieChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
echarts.use([TooltipComponent, PieChart, CanvasRenderer]);
export default {
  data() {
    return {
      id: "pieChart",
      option: {
        tooltip: {
          trigger: "item",
        },
        legend: {
          y: "center",

          right: "5%",
          orient: "vertical",
          itemGap: 30,
          itemWidth: 40,
          itemHeight: 20,
        },
        series: [
          {
            name: "访问来源",
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            itemStyle: {
              borderWidth: 5, //设置border的宽度有多大
              borderColor: "#fff",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "40",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [],
          },
        ],
      },
    };
  },
  props: {
    chartData: Array,
  },
  created() {},
  mounted() {},
  methods: {
    drawCircle(data) {
      let chartDom = document.getElementById(this.id);
      let myChart = echarts.init(chartDom);
      this.option.series[0].data = data;
      this.option && myChart.setOption(this.option);
    },
  },
};
</script>
<style scoped>
.chart {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.myChart {
  width: 100%;
  height: 100%;
}
</style>
<template>
  <rv-dialog :title="title" @close="close">
    <el-form ref="form" :model="menuForm" :rules="menuRules" label-width="80px" :inline="true">
      <el-form-item label="原密码" prop="oldPassword">
        <el-input v-model="menuForm.oldPassword" placeholder="请填写原密码" show-password></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="password">
        <el-input v-model="menuForm.password" placeholder="请填写新密码" show-password></el-input>
      </el-form-item>
    </el-form>
    <div class="option-buttons">
      <el-button type="warning" icon="el-icon-plus" @click="handleUpdate">保存</el-button>
      <el-button type="primary" @click="close">取消</el-button>
    </div>
  </rv-dialog>
</template>

<script>
import { editPassword } from '@/api/system/user'
export default {
  data() {
    return {
      dialogState: true,
      title: "修改密码",
      isUpdate: false,
      menuForm: {
        oldPassword: "",
        password: "",
      },
      menuRules: {
        oldPassword: [
          { required: true, message: "请填写原密码", trigger: "blur" },
          { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' }
        ],
        password: [{ required: true, message: "请填写新密码", trigger: "blur" },
        { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' }
        ]
      }
    };
  },
  methods: {
    handleUpdate() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          editPassword(this.menuForm).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: "修改成功",
                type: "success",
              });
              this.$emit("close", true);
            }
          });
        } else {
          return false;
        }
      });
    },
    close() {
      this.$emit("close");
    },
  },
  mounted() {

  },
};
</script>

<style scoped>
.el-dialog__header {
  background: rgb(26, 36, 62);
  display: flex;
}

.dialog_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  color: #fff;
}

.option-buttons {
  display: flex;
  justify-content: center;
}

::v-deep .el-input-number {
  width: 100%;
  text-align: left;
}

::v-deep .el-input__inner {
  text-align: left;
}

::v-deep .el-select {
  width: 177px;
}
</style>
//订单类型格式化
export function formatOrderType(val) {
  // let type = ['自营','携程','悟空','凹凸','租租'];
  // return type[v-1];

  if (val == '1') {
    return '自营';
  }
  else if (val == '2') {
    return '携程';
  }
  else if (val == '3') {
    return '悟空';
  }
  else if (val == '4') {
    return '凹凸';
  }
  else if (val == '5') {
    return '租租';
  }
  else if (val == '8') {
    return '飞猪';
  }
  else if (val == '9') {
    return '南航';
  }
  else if (val == '11') {
    return '哈啰';
  }
  else if (val == '13') {
    return '车生活';
  }
  else if (val == '15') {
    return '滴滴租车';
  }
  else {
    return '其它';
  }
}
//订单类型格式化
export function formatNopreOrderType(val) {
  // let type = ['自营','携程','悟空','凹凸','租租'];订单类型：1整备，2维保，3调车，4摆渡车，5公务车，99其它
  // return type[v-1];
  if (val == "1") {
    return "整备";
  } else if (val == "2") {
    return "维保";
  } else if (val == "3") {
    return "调车";
  } else if (val == "4") {
    return "摆渡车";
  } else if (val == "5") {
    return "公务车";
  } else if (val == "6") {
    return "加油/补电";
  } else if (val == "7") {
    return "车辆清洗";
  } else if (val == "8") {
    return "检修";
  } else if (val == "9") {
    return "试车";
  } else if (val == "10") {
    return "定损";
  } else if (val == "11") {
    return "检车";
  } else if (val == "12") {
    return "贴膜";
  } else {
    return "其它";
  }
}
//是否关注格式化
export function isOrderFocus(val) {
  return val == '1' ? '关注' : '未关注';
}
//是否关注格式化
export function isLockCarnumber(val) {
  return val == '1' ? '锁定' : '未锁定';
}
//订单状态格式化
export function isOrderStatus(val) {
  if (val == '1') {
    return '确认取车';
  }
  else if (val == '2') {
    return '确认还车';
  }
  else if (val == '3') {
    return '订单取消';
  } else {
    return '无';
  }
}
//非运营订单状态格式化
export function isNoOperateOrderStatus(val) {
  if (val == '1') {
    return '出库';
  }
  else if (val == '2') {
    return '入库';
  } else {
    return '其他';
  }
}
//报警类型
export function formatAlarmType(v) {
  let type = ['设备报警', '入围栏报警', '出围栏报警', '超时未还车', '超时未行驶', '在库车辆异动', '出封闭区域报警', '超时离线'];
  return type[v - 1];
}
export function formatAlarm(typeAlarm, warnName, fenceName, macid, entity_factory) {
  if (typeAlarm == 1) {//设备报警，返回设备号，报警名称
    return formatAlarmType(typeAlarm) + ',' + warnName + ',[' + formatFactory(entity_factory) + ']' + macid + ''
  }
  else if (typeAlarm == 2) {//入围栏报警
    return formatAlarmType(typeAlarm) + ',' + fenceName + ',[' + formatFactory(entity_factory) + ']' + macid + ''
  }
  else if (typeAlarm == 3) {//出围栏报警
    return formatAlarmType(typeAlarm) + ',' + fenceName + ',[' + formatFactory(entity_factory) + ']' + macid + ''
  }
  else if (typeAlarm == 4) {//订单超时报警
    return formatAlarmType(typeAlarm) + ''
  }
  else if (typeAlarm == 5) {//超时未行驶
    return formatAlarmType(typeAlarm) + ',[' + formatFactory(entity_factory) + ']' + macid + ''
  }
  else if (typeAlarm == 6) {//在库车辆异动
    return formatAlarmType(typeAlarm) + ',[' + formatFactory(entity_factory) + ']' + macid + ''
  }
  else if (typeAlarm == 7) {//出封闭区域报警
    return formatAlarmType(typeAlarm) + ',[' + formatFactory(entity_factory) + ']' + macid + ''
  }
  else if (typeAlarm == 8) {//超时离线
    return formatAlarmType(typeAlarm) + ',[' + formatFactory(entity_factory) + ']' + macid + ''
  }
  else {
    return formatAlarmType(typeAlarm) + ''
  }
}
export function formatFactory(type) {
  if (type == 1) {
    return '四海行';
  } else if (type == 2) {
    return '艾能';
  } else if (type == 3) {
    return '自有硬件';
  } else if (type == 4) {
    return '中瑞';
  } else if (type == 5) {
    return '赛格';
  } else {
    return '--';
  }
}
//处理方式格式化
export function isOrderhandletype(val) {
  if (val == 'old') {
    return '按录入';
  }
  else if (val == 'new') {
    return '按接口';
  } else {
    return '无';
  }
}
//处理方式格式化
export function isOrderhandleStatus(val) {
  if (val == '0') {
    return '未消除';
  }
  else if (val == '1') {
    return '已消除';
  } else {
    return '无';
  }
}
//处理方式格式化
export function isOrderhandleStatuss(val) {
  if (val == '0') {
    return '[未]';
  }
  else if (val == '1') {
    return '[已]';
  } else {
    return '[无]';
  }
}
//订单新增修改方式格式化
export function isOrderAddMode(val) {
  if (val == '0') {
    return '接口';
  }
  else if (val == '1') {
    return '手动';
  } else {
    return '';
  }
}
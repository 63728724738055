<template>
  <div>
    <div class="search_cond">
      <el-input v-model="searchName" placeholder="姓名" clearable class="handle-input mr10 mt10"></el-input>
      <el-button type="primary" icon="el-icon-search" @click="search" class="ml20 mt10 searchBtn">搜索</el-button>
      <!--      <el-button
                type="primary"
                icon="el-icon-plus"
                @click="dialogState = true"
                class="ml20 mt10 searchBtn"
            >新增
            </el-button
            >-->
      <el-button
          type="primary"
          icon="el-icon-refresh"
          @click="sync"
          class="ml20 mt10 searchBtn"
          :loading="dialogStateSync"
      >同步
      </el-button>
    </div>

    <rv-table
        ref="rvTable"
        :tableData="tableData"
        :totalNum="totalNum"
        :isLoading="isLoading"
        @getData="getData"
    >
      <el-table-column type="index" label="序号" width="50"/>
      <el-table-column label="登录名" show-overflow-tooltip prop="username"/>
      <el-table-column label="姓名" show-overflow-tooltip prop="nickName"/>
      <el-table-column
          label="角色"
          show-overflow-tooltip
          prop="roleId"
          :formatter="roleFormate"
      />
      <el-table-column
          label="用户状态"
          show-overflow-tooltip
          prop="status"
          :formatter="statusFormate"
      />
      <el-table-column label="手机号" show-overflow-tooltip prop="phone"/>
      <el-table-column label="操作" prop="carColour" fixed="right" width="160">
        <template #default="scoped">
          <el-button type="text" @click="updateData(scoped.row)"
          >修改
          </el-button
          >
          <el-button type="text" @click="deleteData(scoped.row)"
          >删除
          </el-button
          >
          <el-button type="text" @click="editPassword(scoped.row)"
          >重置密码
          </el-button
          >
        </template>
      </el-table-column>
    </rv-table>

    <!-- 添加用户弹窗 -->
    <add-user @close="closeDialog" v-if="dialogState" ref="addUser"/>
    <!-- 添加用户弹窗 -->
    <edit-password
        @close="closeDialog"
        v-if="dialogStatePwd"
        ref="editPassword"
    />
  </div>
</template>
<script>
import addUser from "./components/addUser";
import editPassword from "./components/editPassword";
import {userQueryPage, userDelete, sync} from "@/api/system/user";
import {roleQueryList} from "@/api/system/role";

export default {
  data() {
    return {
      tableData: [],
      totalNum: 0,
      isLoading: false,
      dialogState: false,
      dialogStateSync: false,
      dialogStatePwd: false,
      roleList: [],
      searchName: undefined
    };
  },
  components: {
    addUser,
    editPassword,
  },
  mounted() {
    this.getAllRole();
    this.$refs.rvTable.initData();
  },
  methods: {
    // 角色格式化
    roleFormate(e) {
      return this.roleList.find((i) => i.roleId == e.roleId)?.roleName;
    },
    //状态格式化
    statusFormate(e) {
      if (e.status == 1) {
        return (
            <el-tag effect="dark" type="success">
              启用
            </el-tag>
        );
      } else {
        return (
            <el-tag effect="dark" type="info">
              禁用
            </el-tag>
        );
      }
    },
    // 获取列表
    getData(options) {
      this.isLoading = true;
      userQueryPage({
        pageNum: options.pageNum,
        pageSize: options.pageSize,
        nickName: this.searchName
      })
          .then((res) => {
            if (res.code == 0) {
              this.tableData = res.result.list;
              this.totalNum = res.result.total;
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    //修改数据
    updateData(e) {
      this.dialogState = true;
      this.$nextTick(() => {
        this.$refs.addUser.initData(e);
      });
    },
    // 删除列表
    deleteData(e) {
      this.$confirm("是否要执行此操作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        userDelete({id: e.id}).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.$refs.rvTable.initData();
          }
        });
      });
    },
    // 重置密码
    editPassword(e) {
      this.dialogStatePwd = true;
      this.$nextTick(() => {
        this.$refs.editPassword.initData(e);
      });
    },
    closeDialog(e) {
      if (e) {
        this.$refs.rvTable.initData();
      }
      this.dialogStatePwd = false;
      this.dialogState = false;
    },
    //获取所有角色
    getAllRole() {
      roleQueryList({}).then((res) => {
        if (res.code == 0) {
          this.roleList = res.result;
        }
      });
    },
    search() {
      this.$refs.rvTable.initData()
    },
    sync() {
      this.dialogStateSync = true
      sync().then((res) => {
        this.dialogStateSync = false
        if (res.code === 0) {
          this.$message.success("同步成功")
        }
      })
    }
  },
};
</script>
<style scoped>
.data_wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.handle-input {
  width: 150px;
  display: inline-block;
}

.handle-select {
  width: 120px;
}

.searchBtn {
  height: 30px;
  background: #3b7bcf;
}

.etype {
  font-weight: bold;
  color: #212121;
}

.verticalCol {
  display: flex;
  flex-direction: column;
}

.myDrawer {
  width: 60%;
}

.dialog_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  color: #fff;
}

.btnOnline {
  color: #3b7bcf;
}

.btnOutLine {
  color: #8c939d;
}

.equipList {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.equipList span {
  color: #666666;
  font-size: 16px;
}
</style>
<style less>
</style>

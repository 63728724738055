<template>
  <rv-dialog :title="title" @close="close">
    <el-form
      ref="form"
      :model="roleForm"
      :rules="roleRules"
      label-width="80px"
      :inline="true"
    >
      <el-form-item label="角色名称" prop="roleName">
        <el-input
          v-model="roleForm.roleName"
          placeholder="请填写角色名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="角色权限">
        <el-tree
          ref="rvTree"
          class="tree-box"
          :data="menuList"
          show-checkbox
          node-key="menuId"
          :props="defaultProps"
        >
        </el-tree>
      </el-form-item>
    </el-form>
    <div class="option-buttons">
      <el-button type="warning" icon="el-icon-plus" @click="addRole"
        >保存</el-button
      >
      <el-button type="primary" @click="close">取消</el-button>
    </div>
  </rv-dialog>
</template>

<script>
import { roleAdd, roleEdit } from "@/api/system/role";
import { menuQueryList } from "@/api/system/menu";
export default {
  data() {
    return {
      dialogState: true,
      isUpdate: false,
      title: "新增角色",
      roleForm: {
        roleName: "",
        menuIds: [],
      },
      roleRules: {
        roleName: [
          { required: true, message: "请填写角色名称", trigger: "blur" },
        ],
      },
      menuList: [],
      defaultProps: {
        children: "sysMenus",
        label: "menuName",
      },
    };
  },
  methods: {
    addRole() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          const menuIds = this.$refs.rvTree
            .getCheckedKeys()
            .concat(this.$refs.rvTree.getHalfCheckedKeys());
          if (this.isUpdate) {
            roleEdit({
              roleId: this.roleForm.roleId,
              roleName: this.roleForm.roleName,
              menuIds: menuIds,
            }).then((res) => {
              if (res.code == 0) {
                this.$message({
                  message: "修改成功",
                  type: "success",
                });
                this.$emit("close", true);
              }
            });
          } else {
            roleAdd({
              roleName: this.roleForm.roleName,
              menuIds: menuIds,
            }).then((res) => {
              if (res.code == 0) {
                this.$message({
                  message: "添加成功",
                  type: "success",
                });
                this.$emit("close", true);
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    close() {
      this.$emit("close");
    },
    initData(e) {
      this.isUpdate = true;
      this.title = "修改角色";
      this.roleForm = JSON.parse(JSON.stringify(e));
    },
    getAllMenu() {
      menuQueryList({}).then((res) => {
        if (res.code == 0) {
          this.menuList = res.result;
          if (this.isUpdate) {
            this.$nextTick(() => {
              this.$refs.rvTree.setCheckedKeys([]);
              this.roleForm.sysMenuList.forEach((i) => {
                let node = this.$refs.rvTree.getNode(i);
                if (node.isLeaf) {
                  this.$refs.rvTree.setChecked(node, true);
                }
              });
            });
          }
        }
      });
    },
  },
  mounted() {
    this.getAllMenu();
  },
};
</script>

<style scoped>
.option-buttons {
  display: flex;
  justify-content: center;
}
.tree-box {
  border: 1px solid #e5e6e7;
  border-radius: 4px;
  width: 400px;
}
</style>
<template>
  <div>
    <div class="search_cond">
      <el-button
        type="primary"
        icon="el-icon-plus"
        @click="dialogState = true"
        class="ml20 mt10 searchBtn"
        >新增</el-button
      >
    </div>

    <rv-table
      ref="rvTable"
      :tableData="tableData"
      :totalNum="totalNum"
      :isLoading="isLoading"
      @getData="getData"
    >
      <el-table-column type="index" label="序号" width="50" />
      <el-table-column label="区域名称" prop="areaName" show-overflow-tooltip>
      </el-table-column>
      <el-table-column label="操作" prop="carColour" fixed="right" width="160">
        <template #default="scoped">
          <el-button type="text" @click="updateData(scoped.row)"
            >修改</el-button
          >
          <el-button type="text" @click="deleteData(scoped.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </rv-table>

    <!-- 添加区域弹窗 -->
    <add-area @close="closeDialog" v-if="dialogState" ref="addArea" />
  </div>
</template>
<script>
import addArea from "./components/addArea";
import { areaQueryPage, areaDelete } from "@/api/system/area";
export default {
  data() {
    return {
      tableData: [],
      totalNum: 0,
      isLoading: false,
      dialogState: false,
    };
  },
  components: {
    addArea,
  },
  mounted() {
    this.$refs.rvTable.initData();
  },
  methods: {
    // 获取列表
    getData(options) {
      this.isLoading = true;
      areaQueryPage({
        pageNum: options.pageNum,
        pageSize: options.pageSize,
      })
        .then((res) => {
          if (res.code == 0) {
            this.tableData = res.result.list;
            this.totalNum = res.result.total;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    //修改数据
    updateData(e) {
      this.dialogState = true;
      this.$nextTick(() => {
        this.$refs.addArea.initData(e);
      });
    },
    // 删除列表
    deleteData(e) {
      this.$confirm("是否要执行此操作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        areaDelete({ id: e.id }).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.$refs.rvTable.initData();
          }
        });
      });
    },
    closeDialog(e) {
      if (e) {
        this.$refs.rvTable.initData();
      }
      this.dialogState = false;
    },
  },
};
</script>
<style scoped>
.data_wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.handle-input {
  width: 150px;
  display: inline-block;
}
.handle-select {
  width: 120px;
}
.searchBtn {
  height: 30px;
  background: #3b7bcf;
}
.etype {
  font-weight: bold;
  color: #212121;
}
.verticalCol {
  display: flex;
  flex-direction: column;
}
.myDrawer {
  width: 60%;
}
.dialog_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  color: #fff;
}
.btnOnline {
  color: #3b7bcf;
}
.btnOutLine {
  color: #8c939d;
}
.equipList {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.equipList span {
  color: #666666;
  font-size: 16px;
}
</style>
<style less>
</style>

<template>
  <div class="setting_wrap">
    <div class="item">
      <b>运营订单</b>
    </div>
    <div v-for="$item in settingList" :key="$item.id" class="settingItem">
      <el-radio-group
        v-model="$item.status"
        size="medium"
        @change="changeSetting($item)"
      >
        <el-radio-button label="1">是</el-radio-button>
        <el-radio-button label="0">否</el-radio-button>
      </el-radio-group>
      <span class="warnName">{{ $item.setname }}</span>
      <span class="warnDesc" v-if="$item.setdesc">{{ $item.setdesc }}</span>
      <input
        v-model="$item.setvalue"
        class="line-input"
        v-if="$item.setvalue"
      />
      <span class="warnUnit" v-if="$item.setunit">{{ $item.setunit }}</span>
      <span v-if="$item.settype == 1"
        ><el-button
          type="primary"
          ref="btn_hb"
          icon="el-icon-document"
          @click="alarmSetting($item.ordertype)"
          class="ml20 mt10 searchBtn"
          >监控设置</el-button
        ></span
      >
    </div>
    <div class="item">
      <b>非运营订单</b>
    </div>
    <div v-for="$item in settingPreList" :key="$item.id" class="settingItem">
      <el-radio-group
        v-model="$item.status"
        size="medium"
        @change="changeSetting($item)"
      >
        <el-radio-button label="1">是</el-radio-button>
        <el-radio-button label="0">否</el-radio-button>
      </el-radio-group>
      <span class="warnName">{{ $item.setname }}</span>
      <span class="warnDesc" v-if="$item.setdesc">{{ $item.setdesc }}</span>
      <input
        v-model="$item.setvalue"
        class="line-input"
        v-if="$item.setvalue"
      />
      <span class="warnUnit" v-if="$item.setunit">{{ $item.setunit }}</span>
      <span v-if="$item.settype == 1"
        ><el-button
          type="primary"
          ref="btn_hb"
          icon="el-icon-document"
          @click="alarmSetting($item.ordertype)"
          class="ml20 mt10 searchBtn"
          >监控设置</el-button
        ></span
      >
    </div>
    <div class="item">
      <b>在库车辆</b>
    </div>
    <div v-for="$item in settingCarList" :key="$item.id" class="settingItem">
      <el-radio-group
        v-model="$item.status"
        size="medium"
        @change="changeSetting($item)"
      >
        <el-radio-button label="1">是</el-radio-button>
        <el-radio-button label="0">否</el-radio-button>
      </el-radio-group>
      <span class="warnName">{{ $item.setname }}</span>
      <span class="warnDesc" v-if="$item.setdesc">{{ $item.setdesc }}</span>
      <input
        v-model="$item.setvalue"
        class="line-input"
        v-if="$item.setvalue"
      />
      <span class="warnUnit" v-if="$item.setunit">{{ $item.setunit }}</span>

      <input
        v-model="$item.setvalue2"
        class="line-input"
        v-if="$item.setvalue2"
      />
      <span class="warnUnit" v-if="$item.setunit2">{{ $item.setunit2+$item.setdesc2 }}</span>

      <span v-if="$item.settype == 1"
        ><el-button
          type="primary"
          ref="btn_hb"
          icon="el-icon-document"
          @click="alarmSetting($item.ordertype)"
          class="ml20 mt10 searchBtn"
          >监控设置</el-button
        ></span
      >
    </div>
    <!--      车辆信息-->
    <el-dialog
      :visible.sync="AlarmSettingVisible"
      v-if="AlarmSettingVisible"
      :show-close="false"
      width="60%"
    >
      <div slot="title" class="dialog_header">
        <span>监控设置</span>
        <i
          class="el-icon-close"
          style="font-size: 26px"
          @click="AlarmSettingVisible = false"
        />
      </div>
      <AlarmSetting
        @closeWindow="AlarmSettingVisible = false"
        :strordertype="strordertype"
      />
    </el-dialog>
  </div>
</template>
<script>
import setting from "@/api/monitor";
import { Message } from "element-ui";
import AlarmSetting from "./components/alarmSetting";
export default {
  data() {
    return {
      settingList: [],
      settingPreList: [],
      settingCarList: [], //运营订单，非运营订单，在库车辆
      AlarmSettingVisible: false,
      strordertype: "",
    };
  },
  components: { AlarmSetting },
  mounted() {
    this.loadSettinglist();
  },
  methods: {
    loadSettinglist() {
      setting.list().then((res) => {
        if (res.code == 0) {
          for (let i = 0; i < res.result.length; i++) {
            if (res.result[i].ordertype == 1) {
              this.settingList.push(res.result[i]);
            }
            if (res.result[i].ordertype == 2) {
              this.settingPreList.push(res.result[i]);
            }
            if (res.result[i].ordertype == 3) {
              this.settingCarList.push(res.result[i]);
            }
          }
        }
      });
    },
    changeSetting(val) {
      let param = {
        id: val.id,
        status: val.status,
        setvalue: val.setvalue,
        setvalue2: val.setvalue2
      };
      setting.update(param).then((res) => {
        if (res.code == 0) {
          Message.success({
            message: "[" + val.setname + "]监控设置修改成功！",
          });
        } else {
          Message.error({
            message: res.msg,
          });
        }
      });
    },
    alarmSetting(ordertype) {
      this.strordertype = ordertype;
      this.$nextTick(() => {
        this.AlarmSettingVisible = true;
      });
    },
  },
};
</script>
<style scoped>
.setting_wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.warnName {
  color: rgb(225, 93, 26);
  font-size: 24px;
  font-weight: bold;
  margin-left: 40px;
  margin-right: 40px;
}
.warnDesc {
  color: #212121;
  font-size: 24px;
  font-weight: bold;
}

.warnUnit {
  color: #212121;
  font-size: 24px;
  font-weight: bold;
}
.settingItem {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  background: #fff;
  padding: 10px 20px;
}
.line-input {
  width: 40px;
  color: #3b7bcf;
  font-size: 24px;
  font-weight: bold;
  border: none;
  background: none;
  border-radius: 0;
  border-bottom: 1px #000000 solid;
  min-width: 80px;
  height: 30px;
  text-align: center;
}
input:focus {
  outline: none;
}
.dialog_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  color: #ffffff;
}
</style>
<style less>
</style>
<template>
  <div class="equip_wrap">
    <div class="filter_list">
      <el-select v-model="searchOption.warnType" class="handle-select mr10" placeholder="报警类型"
        @click="warnTypeVisible = true" clearable>
        <el-option key="1" label="设备报警" value="1"></el-option>
        <el-option key="2" label="围栏报警" value="2"></el-option>
        <el-option key="4" label="超时未还车" value="4"></el-option>
        <el-option key="5" label="超时未行驶" value="5"></el-option>
      </el-select>
      <el-select v-model="searchOption.status" class="handle-select mr10" placeholder="是否消除" clearable>
        <el-option key="0" label="未消除" value="0"></el-option>
        <el-option key="1" label="已消除" value="1"></el-option>
      </el-select>
      <el-select v-model="searchOption.userName" class="handle-select mr10" placeholder="操作人" clearable>
        <el-option :key="item.id" :label="item.nickName" :value="item.username" v-for="item in userList"></el-option>
      </el-select>
      <!-- <el-select
        v-model="searchOption.clearStatus"
        class="handle-select mr10 mt10"
        placeholder="是否提醒"
        clearable
      >
        <el-option key="0" label="未提醒" value="0"></el-option>
        <el-option key="1" label="已提醒" value="1"></el-option>
      </el-select> -->
      <!--  报警时间：<el-date-picker
        v-model="alarmDateRanges"
        type="datetimerange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      >
      </el-date-picker> -->
      <el-button type="primary" icon="el-icon-search" @click="handleSearch" class="ml20 mt10 searchBtn">搜索</el-button>
    </div>
    <div class="data_list">
      <el-table :data="tableData" highlight-current-row :style="'width: 100%;overflow-y:auto;'"
        :header-cell-style="{ background: '#FFF', color: '#212121' }">
        <el-table-column type="index" label="序号" align="left" :index="indexMethod" width="50" />

        <el-table-column prop="sendTime" label="报警时间" align="left" />
        <el-table-column label="是否消除" align="left">
          <template slot-scope="scope">
            <span>{{ isOrderhandleStatus(scope.row.status) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="报警内容" align="left" width="400">
          <template slot-scope="scope">
            <div>
              <span>{{
                formatAlarm(
                  scope.row.typeAlarm,
                  scope.row.warnName,
                  scope.row.fenceName,
                  scope.row.macid,
                  scope.row.entity_factory
                )
              }}</span>,
              <!-- <span>{{scope.row.warnName}}</span> -->
              <span>{{ scope.row.sendTime }}</span>
            </div>
          </template>
        </el-table-column>
        <!--<el-table-column  prop="entityNumber" label="报警内容" align="left" />
    <el-table-column label="报警时间" align="left" >
      <template slot-scope="scope">
        <span>{{formateEquipType(scope.row.entityType)}}</span>
      </template>
    </el-table-column> -->

        <el-table-column label="备注" align="left">
          <template slot-scope="scope">
            <span>{{ scope.row.remark }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="insertTime" label="备注时间" align="left" />
        <el-table-column prop="handleBy" label="操作者" align="left" />
        <!-- <el-table-column label="操作" align="left">
      <template slot-scope="scope">
        <el-button type="text" @click="deleteBind(scope.row.entityNumber,scope.row.entityFactory)">解绑</el-button>
      </template>
    </el-table-column> -->
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination background @current-change="handleCurrentChange" layout="prev, pager, next,total"
        :total="searchOption.totalCount" :page-size="10">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import monitor from "@/api/monitor";
import { userQueryPage } from "@/api/system/user";
//import {Message} from 'element-ui'
import {
  formatAlarmType,
  formatAlarm,
  isOrderhandleStatus,
} from "@/api/orderview";
//import {formatFactory} from '../api/carview'//equipTypeFormat
export default {
  data() {
    return {
      bindEquipVisible: false,
      alarmDateRanges: [], //报警时间
      searchOption: {
        name: "",
        pageSize: "10",
        totalCount: 1,
        pageNum: 1,
        orderSn: "",
        vehicleSn: "",
        userName: "",
        warnType: "",
        city: "",
        status: "",
        clearStatus: "",
        orderStatus: "",
        cur_page: 1,
        autoload: "30",
      },
      tableData: [],
      userList: [],
    };
  },
  props: {
    orderNum: String,
  },
  comments: {},
  mounted() {
    this.loadData();
    this.getAllUser();
  },
  methods: {
    getAllUser() {
      userQueryPage({
        pageNum: 1,
        pageSize: 999,
      }).then((res) => {
        let list = res.result.list;
        list.unshift({
          nickName: "admin",
          id: 1,
        });
        this.userList = res.result.list;
      });
    },
    handleSearch() {
      this.loadData();
    },
    loadData() {
      // let startTime = "";
      // let endTime = "";
      // if (this.alarmDateRanges != null && this.alarmDateRanges.length > 0) {
      //   let s1 = new Date(this.alarmDateRanges[0]).getTime();
      //   let s2 = new Date(this.alarmDateRanges[1]).getTime();
      //   startTime = s1;
      //   endTime = s2;
      // }
      let param = {
        pageNum: this.searchOption.pageNum,
        pageSize: this.searchOption.pageSize,
        orderNumber: this.orderNum,
        status: this.searchOption.status,
        handleBy: this.searchOption.userName,
        typeAlarm: this.searchOption.warnType,
      };
      monitor.getAlarmHistoryList(param).then((res) => {
        if (res.code == 0) {
          this.tableData = res.result.list;
          this.searchOption.totalCount = res.result.total;
        }
      });
    },
    formatAlarmType(v) {
      return formatAlarmType(v);
    },
    isOrderhandleStatus(v) {
      return isOrderhandleStatus(v);
    },
    formatAlarm(typeAlarm, warnName, fenceName, macid, entity_factory) {
      return formatAlarm(typeAlarm, warnName, fenceName, macid, entity_factory);
    },
    // deleteBind(number,facID){
    //   let param = {
    //     fenceId:this.fenceId,
    //     entityNumber:number,
    //     entityFactory:facID
    //   }
    //   equipApi.removeBind(param).then(res=>{
    //     if(res.code == 0) {
    //       this.$emit('success')
    //     }else{
    //       Message.error({
    //         message: res.msg,
    //       })
    //     }
    //   })
    // },
    handleCurrentChange(val) {
      this.searchOption.pageNum = val;
      this.loadData();
    },
    indexMethod(index) {
      let curPage = this.searchOption.pageNum;
      let pageSize = this.searchOption.pageSize;
      return index + 1 + (curPage - 1) * pageSize;
    },
  },
};
</script>
<style scoped>
.el-dialog__body {
  padding-top: 20px;
}

.equip_wrap {
  display: flex;
  flex-direction: column;
}

.filter_list {
  display: flex;
  align-items: center;
  margin-top: -10px;
  margin-bottom: 10px;
}

.searchBtn {
  height: 30px;
  background: #3b7bcf;
}

.handle-input {
  width: 200px;
  display: inline-block;
}

.dialog_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  color: #fff;
}
</style>
<style less>
.el-dialog__header {
  background: rgb(26, 36, 62);
  display: flex;
}
</style>
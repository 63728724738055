<template>
  <div class="real-time">
    <div class="top-wrapper">
      <div class="wrapper-left">
        <div style="width: 100%; height: 100%" ref="sctterMap"></div>
        <div class="mapChoose" @click="backArea()">返回</div>
        <div class="dataChoose">
          <el-date-picker style="width: 230px;" v-model="dateTime" type="daterange" value-format="yyyy-MM-dd"
            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
          <el-select v-model="hourValue" style="width: 60px" class="handle-select ml10 mr10 mt10" placeholder="查询小时">
            <el-option key="0" label="0" value="0"></el-option>
            <el-option key="1" label="1" value="1"></el-option>
            <el-option key="2" label="2" value="2"></el-option>
            <el-option key="3" label="3" value="3"></el-option>
            <el-option key="4" label="4" value="4"></el-option>
            <el-option key="5" label="5" value="5"></el-option>
            <el-option key="6" label="6" value="6"></el-option>
            <el-option key="7" label="7" value="7"></el-option>
            <el-option key="8" label="8" value="8"></el-option>
            <el-option key="9" label="9" value="9"></el-option>
            <el-option key="10" label="10" value="10"></el-option>
            <el-option key="11" label="11" value="11"></el-option>
            <el-option key="12" label="12" value="12"></el-option>
            <el-option key="13" label="13" value="13"></el-option>
            <el-option key="14" label="14" value="14"></el-option>
            <el-option key="15" label="15" value="15"></el-option>
            <el-option key="16" label="16" value="16"></el-option>
            <el-option key="17" label="17" value="17"></el-option>
            <el-option key="18" label="18" value="18"></el-option>
            <el-option key="19" label="19" value="19"></el-option>
            <el-option key="20" label="20" value="20"></el-option>
            <el-option key="21" label="21" value="21"></el-option>
            <el-option key="22" label="22" value="22"></el-option>
            <el-option key="23" label="23" value="23"></el-option>
          </el-select>
          <el-button type="primary" @click="changeMapData">查询</el-button>
        </div>
      </div>
      <div class="wrapper-right">
        <div style="padding: 10px;border: 1px solid #000000;margin-bottom: 16px;">
          <div class="alert-time">
            <div class="time-title">报警统计时间</div>
            <div class="time-select">
              <el-date-picker v-model="alterTime" type="datetimerange" range-separator="至" start-placeholder="开始日期"
                end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss">
              </el-date-picker>
            </div>
            <el-button type="primary" @click="getCountList">查询</el-button>
          </div>

          <div class="operation-list">
            <div class="list-title">
              <img class="title-icon" src="../../../assets/images/realTime/icon1.png" alt="" />
              <span class="title-text">运营订单监控情况</span>
            </div>
            <div class="list-menu">
              <div class="menu-item">
                <div class="item-label">设备报警</div>
                <div class="item-value">
                  {{ dataInfo.orderEntityAlarmCount || 0 }}
                </div>
              </div>
              <div class="menu-item">
                <div class="item-label">围栏报警</div>
                <div class="item-value">
                  {{ dataInfo.orderFenceAlarmCount || 0 }}
                </div>
              </div>
              <div class="menu-item">
                <div class="item-label">订单报警</div>
                <div class="item-value">
                  {{ dataInfo.orderAlarmCount || 0 }}
                </div>
              </div>
              <div class="menu-item">
                <div class="item-label">出封闭区域</div>
                <div class="item-value">
                  {{ dataInfo.orderRegionAlarmCount || 0 }}
                </div>
              </div>
              <!-- <div class="menu-item">
              <div class="item-label">围栏报警</div>
              <div class="item-value">1200</div>
            </div>
            <div class="menu-item">
              <div class="item-label">订单报警</div>
              <div class="item-value">1200</div>
            </div>
            <div class="menu-item">
              <div class="item-label">超时离线</div>
              <div class="item-value">1200</div>
            </div>
            <div class="menu-item">
              <div class="item-label">出封闭区域</div>
              <div class="item-value">1200</div>
            </div> -->
            </div>
          </div>

          <div class="operation-list list2">
            <div class="list-title">
              <img class="title-icon" src="../../../assets/images/realTime/icon2.png" alt="" />
              <span class="title-text">非运营订单监控情况</span>
            </div>
            <div class="list-menu">
              <div class="menu-item">
                <div class="item-label">设备报警</div>
                <div class="item-value">
                  {{ dataInfo.noOperateEntityAlarmCount || 0 }}
                </div>
              </div>
              <div class="menu-item">
                <div class="item-label">围栏报警</div>
                <div class="item-value">
                  {{ dataInfo.noOperateFenceAlarmCount || 0 }}
                </div>
              </div>
              <div class="menu-item">
                <div class="item-label">订单报警</div>
                <div class="item-value">
                  {{ dataInfo.noOperateAlarmCount || 0 }}
                </div>
              </div>
              <div class="menu-item">
                <div class="item-label">出封闭区域</div>
                <div class="item-value">
                  {{ dataInfo.noOperateRegionAlarmCount || 0 }}
                </div>
              </div>
            </div>
          </div>
          <div class="operation-list list3" style="margin: 0;">
            <div class="list-title">
              <img class="title-icon" src="../../../assets/images/realTime/icon3.png" alt="" />
              <span class="title-text">在库车辆监控情况</span>
            </div>
            <div class="list-menu">
              <!-- <div class="menu-item">
              <div class="item-label">在库车辆</div>
              <div class="item-value">1200</div>
            </div> -->
              <div class="menu-item">
                <div class="item-label">车辆移动</div>
                <div class="item-value">
                  {{ dataInfo.inCarMoveAlarmCount || 0 }}
                </div>
              </div>
              <div class="menu-item">
                <div class="item-label">出封闭区域</div>
                <div class="item-value">
                  {{ dataInfo.nCarRegionAlarmCount || 0 }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="operation-list list2">
          <div class="list-title">
            <img class="title-icon" src="../../../assets/images/realTime/icon2.png" alt="" />
            <span class="title-text">订单设备监控情况</span>
          </div>
          <div class="list-menu">
            <div class="menu-item">
              <div class="item-label">监控订单</div>
              <div class="item-value">{{ dataInfo1.orderNum || 0 }}</div>
            </div>
            <div class="menu-item">
              <div class="item-label">监控设备</div>
              <div class="item-value">{{ dataInfo1.entityNum || 0 }}</div>
            </div>
            <div class="menu-item">
              <div class="item-label">重点关注订单</div>
              <div class="item-value">{{ dataInfo1.focusNum || 0 }}</div>
            </div>
          </div>
        </div>
        <div class="operation-list list4" style="margin: 0;">
          <div class="list-title">
            <img class="title-icon" src="../../../assets/images/realTime/icon4.png" alt="" />
            <span class="title-text">在库车辆监控情况</span>
          </div>
          <div class="list-menu">
            <div class="menu-item1" v-for="(item, index) in tableData" :key="index" @click="selectTableData(item)">
              <div class="item-content" v-if="Array.isArray(item.Data) && item.Data.length > 0">
                <span class="content-title">{{ item.SystemName }}</span>
                <span class="content-value">
                  <span class="value-num">{{ item.Data.length }}</span>
                  条未处理</span>
              </div>
              <div class="item-content h-68" v-else>{{ item.SystemName }}</div>
              <div class="item-status red" :class="[item.DockingStatus == 1 ? 'green' : 'red']">
                {{ item.DockingStatus == 1 ? "正常" : "故障" }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-wrapper">
      <div class="chartLine">
        <dailyChart ref="dailyChart"></dailyChart>
      </div>
      <div class="chartcircle">
        <circleChart ref="circleChart"></circleChart>
      </div>
    </div>

    <el-dialog :visible.sync="messageVisible" v-if="messageVisible" :show-close="false" width="60%">
      <div slot="title" class="dialog_header">
        <span>未处理信息</span>
        <i class="el-icon-close" style="font-size: 26px" @click="messageVisible = false" />
      </div>
      <el-table ref="table" :data="tableInfoData" highlight-current-row
        :header-cell-style="{ background: '#FFF', color: '#212121' }">
        <el-table-column type="index" label="序号" :index="indexMethod" align="left" width="50" />
        <el-table-column label="信息" prop="showInfo" />
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import * as echarts from "echarts";
import dailyChart from "./components/dailyWarn";
import circleChart from "./components/circlePanel";
import dashboard from "@/api/dashboard";
import { cityCarGather } from "@/api/statistics/cityCar";
export default {
  data() {
    return {
      alterTime: [],
      myCharts: null,
      messageVisible: false,
      dateValue: "",
      dateValue1: "",
      dateTime: [],
      hourValue: "0",
      geoJson: {
        features: [],
      },
      parentInfo: [
        {
          cityName: "全国",
          code: 100000,
        },
      ],
      cityData: [],
      dataInfo: {
        orderEntityAlarmCount: 0,
        orderFenceAlarmCount: 0,
        orderAlarmCount: 0,
        orderRegionAlarmCount: 0,
        noOperateEntityAlarmCount: 0,
        noOperateFenceAlarmCount: 0,
        noOperateAlarmCount: 0,
        noOperateRegionAlarmCount: 0,
        inCarMoveAlarmCount: 0,
        inCarRegionAlarmCount: 0,
      },
      dataInfo1: {},
      tableData: [],
      tableInfoData: [],
    };
  },
  components: {
    dailyChart,
    circleChart,
  },
  mounted() {
    this.dateTime = [this.getNowDate(new Date()), this.getNowDate(new Date())]
    this.alterTime = [
      this.format(new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000)),
      this.format(new Date()),
    ];
    this.hourValue =
      new Date().getHours() - 1 < 0 ? 0 + "" : new Date().getHours() - 1 + "";
    this.getCityData(this.parentInfo[this.parentInfo.length - 1].code);
    this.loadData();
  },
  beforeDestroy() {
    if (this.myCharts) {
      this.myCharts.dispose()
      this.myCharts = null
    }

  },
  methods: {
    changeMapData() {
      // let startTime = 0
      // let endTime = 0
      // if (this.dateValue) {
      //   startTime = new Date(this.dateValue)
      // }
      // if (this.dateValue1) {
      //   endTime = new Date(this.dateValue1)
      // }
      // if (this.dateValue && this.dateValue1 && startTime > endTime) {
      //   this.$message.warning('开始时间不能大于结束时间')
      //   return false
      // }
      this.getCityData(this.parentInfo[this.parentInfo.length - 1].code);
    },
    indexMethod(index) {
      // let curPage = 1;
      // let pageSize = 2;
      return index + 1;
    },
    selectTableData(item) {
      if (!Array.isArray(item.Data)) {
        return false;
      }
      let list = [];
      if (Array.isArray(item.Data) && item.Data.length) {
        let data = JSON.parse(JSON.stringify(item.Data));

        data.forEach((i) => {
          list.push({
            showInfo: JSON.stringify(i),
          });
        });
        this.tableInfoData = list;
      } else {
        this.tableInfoData = [];
      }
      this.messageVisible = true;
    },
    format(dat) {
      //获取年月日，时间
      var year = dat.getFullYear();
      var mon =
        dat.getMonth() + 1 < 10
          ? "0" + (dat.getMonth() + 1)
          : dat.getMonth() + 1;
      var data = dat.getDate() < 10 ? "0" + dat.getDate() : dat.getDate();
      var hour = dat.getHours() < 10 ? "0" + dat.getHours() : dat.getHours();
      var min =
        dat.getMinutes() < 10 ? "0" + dat.getMinutes() : dat.getMinutes();
      var seon =
        dat.getSeconds() < 10 ? "0" + dat.getSeconds() : dat.getSeconds();
      var newDate =
        year + "-" + mon + "-" + data + " " + hour + ":" + min + ":" + seon;
      return newDate;
    },
    getNowDate(dat) {
      var year = dat.getFullYear();
      var mon =
        dat.getMonth() + 1 < 10
          ? "0" + (dat.getMonth() + 1)
          : dat.getMonth() + 1;
      var data = dat.getDate() < 10 ? "0" + dat.getDate() : dat.getDate();
      var newDate = year + "-" + mon + "-" + data;
      return newDate;
    },
    getCityData(code) {
      cityCarGather({
        startDate: Array.isArray(this.dateTime) && this.dateTime.length > 0 ? this.dateTime[0] : '',
        endDate: Array.isArray(this.dateTime) && this.dateTime.length > 0 ? this.dateTime[1] : '',
        hour: this.hourValue,
        type: "1",
      }).then((res) => {
        if (res.code == 0 && res.result && res.result.dataJson) {
          let cityData = res.result.dataJson;
          if (this.parentInfo.length == 2) {
            let cities = cityData.find((i) => i.code == code).cities;
            this.initData(cities, code);
          } else {
            this.initData(cityData, code);
          }
        } else {
          this.initData([], code);
        }
      });
    },
    initData(cityData, code) {
      dashboard.getGeoJson({
        code: code + '_full'
      }).then(res => {
        if (this.myCharts) {
          this.myCharts.dispose()
          this.myCharts = null
        }

        this.initChart(res.result, cityData, code);
      })
      // axios
      //   .get(
      //     `https://geo.datav.aliyun.com/areas_v3/bound/geojson?code=${code}_full`
      //   )
      //   .then((res) => {

      //   });
    },
    initChart(mapData, cityData) {
      let pointData = [];
      let mapList = [];
      cityData.forEach((i) => {
        mapList.push({
          name: i.name,
          cityCode: parseInt(i.code),
          value: i.count,
        });
        pointData.push({
          name: i.name,
          value: [i.lng, i.lat, i.count],
          cityCode: parseInt(i.code),
        });
      });

      var maxData = pointData[0]?.value || 0;
      this.myCharts = echarts.init(this.$refs.sctterMap);
      let options = {
        tooltip: {
          trigger: "item",
          formatter: (p) => {
            let val = p.value;
            if (!val) {
              val = 0;
            }
            if (val.length == 3) {
              val = val[2];
            }
            let txtCon = p.name + ":" + val;
            return txtCon;
          },
        },
        title: {
          show: true,
          left: "center",
          top: "15",
          text: this.parentInfo[this.parentInfo.length - 1].cityName + "",
          textStyle: {
            color: "rgb(179, 239, 255)",
            fontSize: 16,
          },
        },
        toolbox: {
          show: false,
          feature: {
            restore: {
              show: false,
            },
            dataView: {
              optionToContent: function () {
                return "";
              },
            },
            dataZoom: {
              show: false,
            },
            magicType: {
              show: false,
            },
          },
          iconStyle: {
            normal: {
              borderColor: "#1990DA",
            },
          },
          top: 15,
          right: 35,
        },
        visualMap: {
          show: false,
          min: 0,
          max: 1000,
          left: "3%",
          bottom: "5%",
          calculable: true,
          seriesIndex: [0],
          inRange: {
            color: ["#1E62AC", "#1E62AC", "#1E62AC"],
          },
          textStyle: {
            color: "#24CFF4",
          },
        },
        geo: {
          map: "Map",
        },
        series: [
          {
            name: "地图",
            type: "map",
            map: "Map",
            roam: false, //是否可缩放
            zoom: 1, //缩放比例
            data: mapList,
            label: {
              normal: {
                show: true,
                color: "#f9f9f9", //省份标签字体颜色
                formatter: (p) => {
                  switch (p.name) {
                    case "内蒙古自治区":
                      p.name = "内蒙古";
                      break;
                    case "西藏自治区":
                      p.name = "西藏";
                      break;
                    case "新疆维吾尔自治区":
                      p.name = "新疆";
                      break;
                    case "宁夏回族自治区":
                      p.name = "宁夏";
                      break;
                    case "广西壮族自治区":
                      p.name = "广西";
                      break;
                    case "香港特别行政区":
                      p.name = "香港";
                      break;
                    case "澳门特别行政区":
                      p.name = "澳门";
                      break;
                    default:
                      break;
                  }
                  return p.name;
                },
              },
              emphasis: {
                show: true,
                color: "#f75a00",
              },
            },
            itemStyle: {
              normal: {
                areaColor: "#1E62AC",
                borderColor: "#53D9FF",
                borderWidth: 1.3,
                // shadowBlur: 15,
                // shadowColor: "rgb(58,115,192)",
                // shadowOffsetX: 7,
                // shadowOffsetY: 6,
              },
              emphasis: {
                areaColor: "#8dd7fc",
                borderWidth: 1.6,
                shadowBlur: 25,
              },
            },
          },
          {
            name: "散点",
            type: "effectScatter",
            coordinateSystem: "geo",
            rippleEffect: {
              brushType: "fill",
            },

            itemStyle: {
              normal: {
                color: "#F4E925",
                shadowBlur: 10,
                shadowColor: "#333",
              },
            },
            data: pointData,
            symbolSize: function (val) {
              let value = val[2];

              if (maxData < 10) {
                return value;
              } else if (maxData <= 70 && maxData >= 10) {
                return value / 2;
              } else {
                return value / 4;
              }
            },
            showEffectOn: "render", //加载完毕显示特效
          },
        ],
      };

      echarts.registerMap("Map", mapData);
      this.myCharts.setOption(options, true);

      this.myCharts.on("click", (res) => {
        if (this.parentInfo.length == 2) {
          return false;
        }
        if (res.data) {
          this.parentInfo.push({
            cityName: res.data.name,
            code: res.data.cityCode,
          });
          this.$nextTick(() => {
            this.getCityData(res.data.cityCode);
          });
        }
      });
    },
    getCountList() {
      dashboard
        .getAlarmCountList({
          startTime:
            Array.isArray(this.alterTime) && this.alterTime.length > 0
              ? new Date(this.alterTime[0]).getTime()
              : "",
          endTime:
            Array.isArray(this.alterTime) && this.alterTime.length > 0
              ? new Date(this.alterTime[1]).getTime()
              : "",
        })
        .then((res) => {
          this.dataInfo = res.result;
        });
    },
    loadData() {
      dashboard.getWarnCount4Hour().then((res) => {
        if (res.code == 0 && this.$refs.dailyChart) {
          this.$refs.dailyChart.drawLine(res.result);
        }
      });

      dashboard.getWarnCount().then((res) => {
        if (res.code == 0 && this.$refs.circleChart) {
          this.$refs.circleChart.drawCircle(res.result);
        }
      });
      dashboard
        .getAlarmCountList({
          startTime:
            Array.isArray(this.alterTime) && this.alterTime.length > 0
              ? new Date(this.alterTime[0]).getTime()
              : "",
          endTime:
            Array.isArray(this.alterTime) && this.alterTime.length > 0
              ? new Date(this.alterTime[1]).getTime()
              : "",
        })
        .then((res) => {
          this.dataInfo = res.result;
        });

      dashboard.getOrderCount().then((res) => {
        if (res.code == 0) {
          this.dataInfo1 = res.result;
        }
      });

      dashboard.getConnectStatus().then((res) => {
        if (res.code == 0) {
          res.result.forEach((i) => {
            i.SystemName = i.SystemName.replace("枫叶平台—", "");
          });
          this.tableData = res.result;
        }
      });
    },

    backArea() {
      this.parentInfo = [
        {
          cityName: "全国",
          code: 100000,
        },
      ];
      this.getCityData(this.parentInfo[this.parentInfo.length - 1].code);
    },
  },

};
</script>

<style lang="scss" scoped>
.real-time {
  .top-wrapper {
    display: flex;
    margin-bottom: 20px;

    .wrapper-left {
      width: 50%;
      height: 888px;
      background-color: #bebebe;
      position: relative;

      .mapChoose {
        position: absolute;
        left: 24px;
        top: 14px;
        font-size: 18px;
        color: #ffffff;
        cursor: pointer;
      }

      .dataChoose {
        position: absolute;
        top: 40px;
        right: 24px;

        ::v-deep .el-date-editor {
          width: 140px;
        }

        ::v-deep .el-select {
          width: 68px !important;
        }
      }
    }

    .wrapper-right {
      width: 49%;
      margin-left: 1%;
      /* border: 1px solid #000000;
      padding: 10px; */

      .alert-time {
        display: flex;
        align-items: center;
        height: 50px;
        margin-bottom: 20px;
        background: #fff;
        border-radius: 10px;
        overflow: hidden;

        .time-title {
          width: 19%;
          height: 50px;
          line-height: 50px;
          text-align: center;
          color: #fff;
          background: #2793e7;
        }

        .time-select {
          width: 60%;
          margin-left: 5%;
          margin-right: 5%;

          ::v-deep .el-date-editor {
            width: 100%;
          }
        }
      }

      .operation-list {
        background: #fff;
        height: auto;
        overflow: hidden;
        padding-bottom: 17px;
        border-left: 8px solid;
        margin-bottom: 16px;
        border-image: linear-gradient(#46dfd6, #109ad1) 30 30;

        &.list2 {
          border-image: linear-gradient(#9370fe, #fc5da0) 30 30;
        }

        &.list3 {
          border-image: linear-gradient(#f8ce8b, #f46b78) 30 30;
        }

        &.list4 {
          border-image: linear-gradient(#7650fd, #a7cbfe) 30 30;
        }

        .list-title {
          width: 98%;
          height: 50px;
          line-height: 50px;
          border-bottom: 1px solid #e6e6e6;
          margin: 0 auto;
          font-size: 18px;
          font-weight: bold;
          display: flex;
          align-items: center;

          .title-icon {
            margin-right: 10px;
          }

          .title-text {}
        }

        .list-menu {
          width: 98%;
          height: auto;
          overflow: hidden;
          margin: 0 auto;
          display: flex;
          flex-wrap: wrap;

          .menu-item {
            width: 21%;
            margin-right: 2%;
            background: #eff3fb;
            text-align: left;
            padding: 5px 1%;
            border-radius: 10px;
            margin-top: 10px;

            .item-label {
              font-size: 16px;
            }

            .item-value {
              font-weight: bold;
              font-size: 24px;
            }
          }

          .menu-item1 {
            width: 21%;
            margin-right: 2%;
            background: #eff3fb;
            text-align: left;
            padding: 5px 1%;
            border-radius: 10px;
            margin-top: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .item-content {
              font-size: 16px;
              height: 68px;
              width: 60%;
              font-weight: bold;
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              &.h-68 {
                line-height: 68px;
              }

              .content-title {
                font-size: 16px;
                font-weight: bold;
                line-height: 40px;
                color: #000000;
              }

              .content-value {
                font-size: 14px;
                line-height: 28px;
                color: #000000;

                .value-num {
                  font-weight: bold;
                }
              }
            }

            .item-status {
              color: #fff;
              border-radius: 10px;
              padding: 6px;
              font-size: 12px;

              &.red {
                background: #ff0000;
              }

              &.green {
                background: #0dba00;
              }
            }
          }
        }
      }
    }
  }

  .bottom-wrapper {
    display: flex;

    .chartLine {
      width: 60%;
      height: 400px;
      background: #fff;
    }

    .chartcircle {
      width: 40%;
      height: 400px;
      background: #fff;
    }
  }
}

.dialog_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  color: #fff;
  background: rgb(26, 36, 62);
}
</style>
